import Jobin from "jobin-client";
import { apiId, jsKey, urlServer } from "./credentials";
import { getCookie } from "../utils/cookie";
import { elementOf } from "../utils/formatter";

const fetchServices = async (prevSelection,isGoldSession_=false,isProDispat_=false) => {
    Jobin.initServer(apiId, jsKey, urlServer);
    const agent = JSON.parse(
        getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
    );
        if (isGoldSession_){

            var selectedIds =
                prevSelection && Array.isArray(prevSelection)
                    ? prevSelection.map(item => item.id)
                    : [];
        }else if (isProDispat_){

            var selectedIds =
                prevSelection && Array.isArray(prevSelection)
                    ? prevSelection.map(item => item)
                    : [];
        }else{
            var selectedIds =
                prevSelection && Array.isArray(prevSelection)
                    ? prevSelection.filter(item => item.selected).map(item => item.id)
                    : [];
        }

    Jobin.initToken(agent ? agent.token : undefined);
    try {
        const result = await Jobin.Guild.getFathers();
        if (result.status !== "error") {
            let arr = result.result.map(service => {

                if (isGoldSession_ && elementOf(service.id, selectedIds)){
                    return {
                        id: service.id,
                        name: service.get("Type"),
                        icon:service.get("Icon")?.url(),
                        selected: false
                    };
                }else if(!isGoldSession_){
                    return {
                        id: service.id,
                        name: service.get("Type"),
                        icon:service.get("Icon")?.url(),
                        selected: elementOf(service.id, selectedIds)
                    };
                }
            });
           arr=   arr.filter((item)=> {return item!==undefined} )

            return [
                {
                    id: "all",
                    name: "Todos",
                    selected: false
                }
            ].concat(arr);
        }
    } catch (error) {
        console.error(error);
    }
};

export default fetchServices;
