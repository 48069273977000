const rutes =(userType)=>{
    switch (userType) {
        case "SearchGarages":
            return [
                "/search-garages"
                ]
            
        break;
        case "goldUser":
            return [
                "/job",
                "/job/id",
                "/maps",
                "/user",
                "/explore",
                "/user/id",
                "/purchased-jobs"
                ]
            
        break;
        case "B2B":
            return [
                "/job",
                "/job/id",
                "/kpis"
                ]
        break;
        case "mediator":
            return [
                "/job",
                "/job/id"
                ]
        break;
        case "Ops":
            return [
                "/job",
                "/explore",
                "/job/id",
                "/job/new",
                "/user",
                "/user/id",
                "/user/signup",
                "/billing",
                "/billing/create",
                "/business",
                "/business/id",
                "/maps",
                "/match"
                ]
        break;
        case "ChiefOps":
            return [
                "/job",
                "/job/id",
                "/job/new",
                "/user",
                "/user/id",
                "/user/signup",
                "/billing",
                "/billing/create",
                "/business",
                "/business/id",
                "/explore",
                "/maps",
                "/kpis",
                "/match"
                ]
        break;
        case "CEO":
            return [
                "/job",
                "/job/id",
                "/job/new",
                "/user",
                "/user/id",
                "/user/signup",
                "/billing",
                "/billing/create",
                "/explore",
                "/business",
                "/business/id",
                "/kpis",
                "/agents",
                "/maps",
                "/verticals",
                "/match",
                "/campains"
                ]
        case "Tech":
            return [
                "/job",
                "/job/id",
                "/job/new",
                "/user",
                "/user/id",
                "/user/signup",
                "/billing",
                "/billing/create",
                "/explore",
                "/business",
                "/business/id",
                "/kpis",
                "/agents",
                "/maps",
                "/verticals",
                "/match",
                "/campains"
                ]
        case "QA":
            return [
                "/job",
                "/job/id",
                "/job/new",
                "/user",
                "/user/id",
                "/user/signup",
                "/billing",
                "/explore",
                "/business",
                "/kpis",
                "/agents",
                "/maps",
                "/verticals",
                "/match"
                ]
        break;
    
        default:
            break;
    }

}
export const accesControl = async (route,userType,userId=null)=>{
    let rutes_ = await rutes(userType);
    let isAllowed = rutes_.map(route_=>{
        if (route_ =="/user/id" && userType == "goldUser" && route == `/user/${userId}` ){
            return [route,route_,"special filter"]
        }else if(route_ !="/user/id"){
            return route.search(route_)!= -1 ? [route,route_,"regular filter"]:null  
        }
    })
    isAllowed = isAllowed.filter(isValid=>isValid != undefined).length > 0
    if(route ==="/"){
        return true
    }
   return isAllowed
}

