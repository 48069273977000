import { apiId, jsKey, urlServer  } from '../constants/credentials';
import axios from "axios"
let Parse = require("parse");

export const changeBudgetData = async (_state, _job, isGoldWoller)=>{

    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    
    let res = await Parse.Cloud.run("setBudgetData",{
        jobId:_job.id,
        state:_state.state,
        origin:_state.origin,
        coments: isGoldWoller ? "Vía JustNow" :_state.coments,
        userId:_state.userId
    });
    
     return res
    
}
export const editUserData = async (dataObject, objectId,isWollerData)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("editUserData",{
        key:dataObject.key,
        value:dataObject.value,
        userId:objectId,
        isWollerData:isWollerData
    });
    return res
}
export const editJobData = async (dataObject, objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("updateJobData",{
        key:dataObject.key,
        value:dataObject.value,
        jobId:objectId
        
    });
    
    
    return res
    
}
export const editExtras = async (dataObject, objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("updateFixedExtras",{
        extras:dataObject,
        jobId:objectId
        
    });
    
    return res
    
}
export const getSugestedPrice = async (jobId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getSugestedPrice",{
      
        jobId:jobId
        
    });
    
    return res
    
}
export const getBusinessData = async (objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getBusinessData",{
        businessId:objectId
    });
    
    return res
    
}
export const closeAuctionJob = async (objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        jobId: objectId
    }

    let res = await Parse.Cloud.run("updateState", data);
    return res
    
}
export const getWollesbyJob = async (objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        jobId: objectId
    }

    let res = await Parse.Cloud.run("getWollesbyJob", data);
    return res
}   
export const getParsedInfo = async (notification)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getParsedInfo",{
        id:notification

    });

    return res
    
}
export const getCrmUserData = async (objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        userId: objectId
    }
    let res = await Parse.Cloud.run("getCrmUserData", data);
    return res
}
export const sendMailBudgetState = async (_dataJob,coments,budget)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    if(_dataJob.business && _dataJob.business.id === "Uo1ncXDbA2"){

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer " + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEiLCJlbWFpbCI6InVzZXJAcGFzcyJ9.OQkzS6qH5gWNWpyD5AJn7ncYw2Fs4PLi-_jXgR9b0Oo'
            }
        };
        let service = _dataJob.title.split(" ")
        let data = {

            referencia:service[0],
            estado:budget.state ==="estimated"? "Estimado":"Desestimado",
        }
    
        axios.post('https://justnow.wolly.grupomobius.com/api/pelayo-reply', data, axiosConfig)
            .then((res) => {
                return  res
            })
            .catch((err) => {
                console.error("AXIOS ERROR: ", err);
                return err
            })

    }else{
        return {data:[_dataJob, coments, budget], state:"not pelayo"}
    }
    
}
export const getUserData = async (objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        id: objectId
    }

    let res = await Parse.Cloud.run("getUserById", data);
    return res
    
}
export const getWollerProfile = async (objectId)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        wollerId: objectId
    }

    let res = await Parse.Cloud.run("getWollerProfile", data);
    return res
    
}
export const fetchKpis = async (statDate, endDate, provinces, guilds, business)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        startDate:statDate,
        endDate:endDate,
        provinces:provinces,
        guilds:guilds,
        business:business,
    }
    
    let res = await Parse.Cloud.run("fetchKpis", data);
    return res
    
}
export const getFiltredJobs = async (userId, JobType, startDate, endDate, provinces,guilds,  jobId,limit)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        userId:userId,
        JobType:JobType,
        provinces:provinces,
        guilds:guilds,
        startDate:startDate,
        endDate:endDate,
        jobId:jobId,
        limit:limit
    }

    let res = await Parse.Cloud.run("getFiltredJobs", data);
    return res
}
export const searchWollers = async (levelArray, distance, location, minPoints, minLeads, lastConnectionStartDate, lastConnectionEndDate, guilds, phone, limit, message, business)=>{
   
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        levelArray:levelArray,
        distance:distance,
        location:location,
        minPoints:minPoints,
        minLeads:minLeads,
        lastConnectionStartDate:lastConnectionStartDate,
        lastConnectionEndDate:lastConnectionEndDate,
        guilds:guilds,
        phone:phone,
        limit:limit,
        message:message,
        business: business
    }

    let res = await Parse.Cloud.run("searchWollers", data);
    return res

}
export const searchWollerChat = async (id)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
       id:id
    }

    let res = await Parse.Cloud.run("searchWollerChat", data);
    return res

}
export const saveMessage = async (message,user)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    message.user=user.id
    let res = await Parse.Cloud.run("saveMessage", message);
    return res
}
export const setReaded = async (id)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
       id:id
    }

    let res = await Parse.Cloud.run("setReaded", data);
    return res

}
export const getNotificationsByJob = async (jobsArray)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        jobsArray:jobsArray
    }

    let res = await Parse.Cloud.run("getNotificationsByJob", data);
    var wollersByJob =[]
	res.map((notif)=> {
		wollersByJob[`${notif.get("Jobs").id}`] = []	
	})
	res.forEach((notif)=> {
		
		if(wollersByJob[`${notif.get("Jobs").id}`].length>0){
			wollersByJob[`${notif.get("Jobs").id}`]= [...wollersByJob[`${notif.get("Jobs").id}`],notif.get("FromUser")]
		}else{
			wollersByJob[`${notif.get("Jobs").id}`]= [notif.get("FromUser")]
		}
		
	})
    return wollersByJob

}
export const getAgentsByParams = async (field, clouse)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        field:field,
        clouse:clouse
    }

    let res = await Parse.Cloud.run("getAgentsByParams", data);
   
    return res

}
export const searchAgentsById = async (field, clouse)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        field:field,
        clouse:clouse
    }

    let res = await Parse.Cloud.run("searchAgentsById", data);
   
    return res

}
export const getAllWollers = async ()=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    

    let res = await Parse.Cloud.run("getWollers");
   
    return res
    
}
export const GetAllAgents = async ()=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    
    
    let res = await Parse.Cloud.run("getAllAgents");
    
    return res
    
}
export const changeAgentParams = async (id,Lastname=null, Active=null, hashedPassword=null, Name=null, Department=null,email=null,Business=null,User=null,Permissions=null)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        id:id,
        Lastname:Lastname,
        Active:Active,
        hashedPassword:hashedPassword,
        Name:Name,
        Department:Department,
        email:email,
        Business:Business,
        User:User,
        Permissions:Permissions
    }
    
    let res = await Parse.Cloud.run("setAgentParams",data);
    
    return res
    
}
export const DeleteAgent = async (id)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        id:id,
    }

    let res = await Parse.Cloud.run("deleteAgent",data);
   
    return res

}
export const getAssignRules = async ()=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
   

    let res = await Parse.Cloud.run("getAssignRules");
   
    return res

}
export const createAssignRules = async (prior)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
   

    let res = await Parse.Cloud.run("createAssignRules",{prior:prior});
   
    return res

}
export const setAssignRules = async (id,agent=null, provinces=null, guilds=null, business=null, active=null)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        id:id,
        agent:agent,
        provinces:provinces,
        guilds:guilds,
        business:business,
        isActive:active
    }

    let res = await Parse.Cloud.run("setAssignRules",data);
   
    return res

}
export const deleteAssignRules = async (id)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        id:id,
    }

    let res = await Parse.Cloud.run("deleteAssignRules",data);
   
    return res

}
export const updatePriorities = async (rules)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        rules:rules,
    }

    let res = await Parse.Cloud.run("updatePriorities",data);
   
    return res

}
export const createAgent = async (Lastname, hashedPassword, Name, Department, email,User=null,Business=null)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        Lastname:Lastname,
        hashedPassword:hashedPassword,
        Name:Name,
        Department:Department,
        email:email,
        User:User,
        Business:Business
    }

    let res = await Parse.Cloud.run("createAgent",data);
   
    return res

}
export const getFilteredJobsMap = async (selectedVerticals, selectedProvinces, selectedBusiness, selectedAgents, dateRange, selectedTotalSlots) => {

    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let jobs = await Parse.Cloud.run("getFilteredJobsMap", {
                        selectedVerticals: selectedVerticals, 
                        selectedProvinces: selectedProvinces,
                        selectedBusiness: selectedBusiness,
                        dateRange: dateRange,
                        selectedTotalSlots: selectedTotalSlots,
                        selectedAgents: selectedAgents
                    });
    
    return jobs;
}
export const buyJobForWoller = async (jobId, joberId, sessionToken) => {
    try {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("buyJobForJober", {
            jobId: jobId,
            joberId: joberId,
            sessionToken: sessionToken ? sessionToken : null
        });
        return res;
    } catch (error) {
        console.error(error)
    }
}
export const searchJobsByName = async (jobName, joberId=null,userType=null,jobType) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("searchJobsByName", {
        jobName: jobName,
        joberId: joberId,
        userType:userType,
        jobType:jobType
    });
    
    return res;
}
export const getFatherVerticals = async () => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getFatherVerticals");
    return res;
}
export const getAgentsForMaps = async () => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getAgentsForMaps");
    return res;
}
export const getVerticalsWithFaters = async () => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getVerticalsWithFaters");
    return res;
}
export const holdStandBy = async ()=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
   
    let res = await Parse.Cloud.run("holdStandBy");
   
    return res
}
export const getMatchesOfJobs = async (jobId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getMatchesOfJobs",{
        jobId: jobId,
    });

    return res;
}
export const updateVerified = async (jobId,isVerified) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        jobId: jobId,
        verified: isVerified
    }
    let res = await Parse.Cloud.run("updateVerified", data);
    return res
}
export const createOpsNotification = async (jobId, description, typology) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        jobId: jobId,
        comment: description,
        typology: typology
    }
    let res = await Parse.Cloud.run("createOpsNotification", data);
    return res;
}
export const fetchOpsNotification = async (jobId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    let data = {
        jobId: jobId
    }
    
    let res = await Parse.Cloud.run("fetchOpsNotification", data);
    return res;
}
export const deleteOpsNotification = async (notificationId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    let data = {
        notificationId: notificationId
    }
    
    let res = await Parse.Cloud.run("deleteOpsNotification", data);
    return res;
}
export const changeOpsNotificationState = async (notificationId, newState) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        notificationId: notificationId,
        newState: newState
    }
    
    let res = await Parse.Cloud.run("changeOpsNotificationState", data);
    return res;
}
export const getMatchFilteredWollers = async (phone, name, location, distance, selectedLevels, selectedLeads, type) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        phone: phone,
        name: name,
        location: location,
        distance: distance,
        levels: selectedLevels,
        leads: selectedLeads,
        type: type,
        active: true
    }
    
    let res = await Parse.Cloud.run("getMatchFilteredWollers", data);
    return res;
}
export const changeOpsNotificationDescription = async (notificationId, description) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        notificationId: notificationId,
        description: description,
    }
    
    let res = await Parse.Cloud.run("changeOpsNotificationDescription", data);
    return res;
}
export const setVerticalPrices = async (id,wollerPrice, userPrice) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        verticalId: id,
        wollerPrice:parseFloat(wollerPrice),
        userPrice:parseFloat(userPrice)
    }
    
    let res = await Parse.Cloud.run("setVerticalPrices", data);
    return res;
}
export const findJobByIDs = async (id,jobType) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        id:id,
        jobType:jobType
    }
        
    let res = await Parse.Cloud.run("findJobByIDs", data);
    return res;
}
export const updateNJobs = async (wollerId, nJobs) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        nJobs: nJobs,
        wollerId: wollerId
    }

    let res = await Parse.Cloud.run("updateNJobs", data);
    return res;
}
export const getNearGarages = async (latitude, longitude) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        latitude: latitude,
        longitude: longitude
    }

    let res = await Parse.Cloud.run("getNearGarages", data);
    return res;
}
export const searchJobsByUserData = async (name,phone,business,jobType) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        name:name,
        phone:phone,
        businessId:business,
        jobType:jobType
    }
        
    let res = await Parse.Cloud.run("searchJobsByUserData", data);
    return res;
}
export const editOpsNotification = async (jobId, create, type) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobId: jobId,
        create: create,
        type: type
    }

    let res = await Parse.Cloud.run("editOpsNotification", data);
    return res;
}
export const modifyJobLeadPrice = async (jobId, price) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobId: jobId,
        price: price
    }

    let res = await Parse.Cloud.run("modifyJobLeadPrice", data);
    return res;
}
export const getWollersByVerticalsForMap = async (verticals) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        verticals: verticals
    }

    let res = await Parse.Cloud.run("getWollersByVerticalsForMap", data);
    return res;
}
export const getNearJobsByWollerId = async (wollerId, distance) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        wollerId: wollerId,
        distance: distance
    }

    let res = await Parse.Cloud.run("getNearJobsByWollerId", data);
    return res;
}
export const getJobById = async (jobId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobId: jobId
    }

    let res = await Parse.Cloud.run("getJobById", data);
    return res;
}
export const getJobAXAMediator = async (title) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        title: title
    }

    let res = await Parse.Cloud.run("getJobAXAMediator", data);
    return res;
}
export const findJobsByFilters = async ({type,startDate,endDate,provinces,guilds,levels,slots,jobStates,agents,businesses,budget,onlyIncidences,appointmentStart,appointmentEnd,limit,page,userType}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobType:type,//string
        startDate:startDate,//date
        endDate:endDate,//date
        provinces:provinces,//[string]
        guilds:guilds,//[string]
        levels:levels,//[string]
        slots:slots,//[string]
        jobStates:jobStates,//[string]
        agents:agents,//[string]
        businesses:businesses,//[string]
        budget:budget,//[string]
        onlyIncidences:onlyIncidences,//boolean
        appointmentStart:appointmentStart,//Date
        appointmentEnd:appointmentEnd,//Date
        limit:limit,//int
        page:page,//int
        userType:userType//int
    }
    
    let res = await Parse.Cloud.run("findJobsByFilters", data);
    return res;
}
export const findJobsforBiPro = async ({startDate,endDate,provinces,guilds,slots,wollerId,distance,levels}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        startDate:startDate,//date
        endDate:endDate,//date
        provinces:provinces,//[string]
        guilds:guilds,//[string]
        slots:slots,//[int]
        levels:levels,//[int]
        distance:distance,//int
        wollerId:wollerId//string
    }
    
    let res = await Parse.Cloud.run("findJobsforBiPro", data);
    return res;
}
export const getLMPDFData = async (jobId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobId: jobId
    }

    let res = await Parse.Cloud.run("getLMPDFData", data);
    return res;
}
export const searchTableControlOpsData = async (startDate, endDate) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        startDate: startDate._d,
        endDate: endDate._d
    }

    let res = await Parse.Cloud.run("searchTableControlOpsData", data);

    return res;
}
export const getCRMUserTokenById = async (userId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        userId: userId
    }

    let res = await Parse.Cloud.run("getCRMUserTokenById", data);
    return res;
}
export const updateFixedPrice = async (jobId, newPrice) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobId: jobId,
        newPrice: newPrice
    }

    let res = await Parse.Cloud.run("updateFixedPrice", data);

    return res;
}
export const getGenericPdfPCData = async (jobId) => {

    const data = {
        jobId: jobId
    }

    let res = await Parse.Cloud.run("getGenericPdfPCData", data);   

    return res;
}
export const processECIExcel = async (jobs) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        jobs: jobs
    }

    let res = await Parse.Cloud.run("processECIExcel", data);

    return res;
}
export const fetchMatchs = async ({startDate,endDate,business,startServiceDate,endServiceDate,agents,provinces,page,limit}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        startDate:startDate,
        endDate:endDate,
        business:business,
        startServiceDate:startServiceDate,
        endServiceDate:endServiceDate,
        agents:agents,
        provinces:provinces,
        page:page,
        limit:limit
    }

    let res = await Parse.Cloud.run("fetchMatchs", data);

    return res
}
export const getFullWollerProfile = async ({wollerId}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
       wollerId:wollerId
    }

    let res = await Parse.Cloud.run("getFullWollerProfile", data);

    return res
}
export const createJoberTask = async ({wollerId,verticalId,type}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        joberId:wollerId,
        verticalId:verticalId,
        type:type
    }

    let res = await Parse.Cloud.run("createJoberTask", data);

    return res
}
export const deleteJoberTask = async ({wollerId,verticalId,type}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        joberId:wollerId,
        verticalId:verticalId,
        type:type
    }

    let res = await Parse.Cloud.run("deleteJoberTask", data);

    return res
}
export const addJobsImages = async ({wollerId,photos}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        wollerId:wollerId,
        photos:photos
    }

    let res = await Parse.Cloud.run("addJobsImages", data);

    return res
} 
export const removeJobsImages = async ({wollerId,file}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        wollerId:wollerId,
        file:file
    }

    let res = await Parse.Cloud.run("removeJobsImages", data);

    return res
} 
export const getFavoritePacks = async ()=> {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    

    let res = await Parse.Cloud.run("getFavoritePacks");

    return res
}

export const getPurchasedJobsBiPro = async (wollerId, type) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        wollerId:wollerId,
        type: type
    }

    let res = await Parse.Cloud.run("getPurchasedJobsBiPro", data);

    return res
} 
export const generatePaymentLink = async ({priceId,isFromWeb=false,userAccount=false,jobid=false})=> {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        priceId:priceId,
        isFromWeb:isFromWeb,
        userAccount:userAccount,
        jobid:jobid,
    }

    let res = await Parse.Cloud.run("generatePaymentLink",data);

    return res
}
export const createPaymentInProcess = async ({packId,jober=false,from=false,paymentLink=false})=> {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    const data = {
        packId:packId,
        jober:jober,
        from:from,
        paymentIntentId:paymentLink,
    }

    let res = await Parse.Cloud.run("createPaymentInProcess",data);

    return res
}

export const getJobsType = async (userId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getTasks",{ joberId: userId });

    return res
}
export const getCertificationStatus = async (userId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getCertificationStatus",{ userId: userId });

    return res
}
export const uploadDocument = async ({document,documentName,format,type,wollerId}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        document:document,
        type:type,
        documentName:documentName,
        format:format,
        wollerId:wollerId,
    }
    let res = await Parse.Cloud.run("uploadDocuments",data);

    return res
}

export const setCampainClick = async (campainId, userId) => {
    return;
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        from: "bipro",
        campainId: campainId,
        userId: userId ? userId : undefined
    }
    
    let res = await Parse.Cloud.run("setCampainClick", data);

    return res; 
}

export const getAllCampains = async () => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    let res = await Parse.Cloud.run("getAllCampains", {});

    return res; 
}

export const getCampainClick = async (dateRange, campainId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    let res = await Parse.Cloud.run("getCampainClick", {dateRange: dateRange, campainId: campainId});

    return res; 
}
export const processPostPay = async ({ammount,userId,jobId,sessionToken}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    const data = {
        ammount:ammount,
        userId:userId,
        jobId:jobId,
        sessionToken:sessionToken
    }
    let res = await Parse.Cloud.run("processPostPay",data);

    return res
}
export const getPaymentData = async (userId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("getPaymentData",{userId:userId});

    return res
}

export const updateWollerActive = async (userId, active) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let res = await Parse.Cloud.run("updateWollerActive",{userId:userId, active: active});

    return res;
}
export const createJober = async ({phone,name,email,nif}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    try {
        const userId = await Parse.Cloud.run("createJober", {
          phone: phone,
          joberName: name,
          email: email,
          nif:nif
        });
        return {
          status: "ok",
          result: await new Parse.Query(Parse.User).get(userId)
        };
    } catch (error) {
        console.error(error);
        return {
            status: "error",
            result: "Error al registrar el nuevo usuario."
        };
    }
}
export const publishProManagerService = async ({jobId}) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    try {
        return await Parse.Cloud.run("publishFixedDraftProManager", {jobId:jobId});
    } catch (error) {
        console.error(error);
        return {
            status: "error",
            result: "Error al intentar Publicar el servicio."
        };
    }
}


