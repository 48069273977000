import React, { useState, useEffect } from "react";
import {getMatchFilteredWollers} from "../../utils/ParserUtilities";
import MainModal from "./MainModal";
import Select from 'react-select';
import Lottie from "react-lottie";
import * as cardSpinnerData from "../../assets/animations/main-loading.json";
import {formatDateWhitoutHour} from "../../utils/formatter";
import { useDebounce } from "../../utils/hooks";

import "../../styles/match-jobs.css";

const MatchJobWollerModal = (props) => {

    const {onClose, isVisible, job, leadPrice} = props;

    const [wollers, setWollers] = useState([]);
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [distance, setDistance] = useState(10);
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [selectedLeads, setSelectedLeads] = useState(leadPrice ? leadPrice : undefined);
    const [loader, setLoader] = useState(true);

    const debouncedPhone = useDebounce(phone, 2000);
    const debouncedName = useDebounce(name, 2000);
    const debouncedDistance = useDebounce(distance, 1000);
    const debouncedLeads = useDebounce(selectedLeads, 1000);

    React.useEffect(
        () => {
            setName("");

            if(phone.length > 3){
                getAllWollers(phone, "", undefined, undefined);
            } else if(wollers.length != 50){
                getAllWollers("", "", distance, selectedLeads);
            }
        },
        [debouncedPhone]
      );

    React.useEffect(
        () => {
            setPhone("");
    
            if(name.length > 2){
                getAllWollers("", name, undefined, undefined);
            } else if(wollers.length != 50){
                getAllWollers("", "", distance, selectedLeads);
            }
        },
        [debouncedName]
    );

    const levels =[
        {value: 1, label: "Bronce"},{value: 2,label: "Plata"}, {value: 3,label: "Oro"}, {value: 4,label: "Platino"}
    ]

    const customStyles = {
        container: provided => ({
          ...provided,
          width: 250
        })
      };

    useEffect(()=> {
        if(job.get("JobType") == "lead"){
            setSelectedLeads(job.get("JobLeadPrice"))
        } 
    },[]);

    useEffect(()=> {
        getAllWollers("","", distance, selectedLeads);
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
          }, 2500);
    },[debouncedLeads, selectedLevels, debouncedDistance]);
    
    const handleLevelChange = e => setSelectedLevels(e);

    const getAllWollers = async (newPhone, newName, newDistance, newLeads) => {
        let res = await getMatchFilteredWollers(newPhone, newName, job.get("Location"), newDistance, selectedLevels, newLeads, [job.get("Type")]);
        setWollers(res)
    }

    const selectJober = async jober => {
        const jobType = job.get("JobType");
        if (jobType === "lead") {
            const joberLeads = jober.get("nJobs");
            const subscription = jober.get("UserSubscription");
            if (joberLeads >= job.get("JobLeadPrice") || subscription) {
                props.asignJober(jober);
            } else alert("Este Jober no tiene suscripción activo o suficientes créditos para aceptar este trabajo.");
        } else props.asignJober(jober);
    
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <MainModal
            style={{ height: "700px", width: "1200px" }}
            title={"Selección de Wollers"}
            isVisible={isVisible}
            onClose={onClose}
        >
            {/* Buscadores */}
            <input
                type="text"
                placeholder="Busca por teléfono..."
                value={phone}
                className="match-search-input"
                onChange={(e)=>setPhone(e.target.value)}
            />
            <input
                type="text"
                placeholder="Busca por nombre..."
                value={name}
                className="match-search-input"
                onChange={(e)=>setName(e.target.value)}
            />

            {/* Filtros */}
            <div className="match-filters">
                <div className="match-filter-slider">
                    <p className="match-filter-title">Distancia máxima: {distance} km</p>
                    <input
                        className="slider"
                        type="range"
                        min={1}
                        max={90}
                        value={distance}
                        onChange={e => {
                            setDistance(e.target.value);
                            setPhone("");
                            setName("");
                        }}
                    />
                </div>
                <div className="match-filter-slider">
                    <p className="match-filter-title">Leads mínimos: {selectedLeads} €</p>
                    <input
                        className="slider"
                        type="range"
                        min={0}
                        max={30}
                        value={selectedLeads}
                        onChange={e => {
                            setSelectedLeads(e.target.value);
                            setPhone("");
                            setName("");
                        }}
                    />
                </div>
                <div className="match-filter-wrapper">
                    <p className="match-filter-select-title">Niveles:</p>
                    <Select
                        isMulti
                        styles={customStyles}
                        options={levels}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={selectedLevels}
                        onChange={handleLevelChange} 
                    />
                </div>
            </div>

            {
                loader && (
                    <div className="woller-match-loader">
                        <Lottie options={defaultOptions} />
                    </div>
                )
            }
            {/* Lista de Wolllers */}
            {
                !loader && (
                    <div className="user-wrapper">
                        { wollers.map( woller => (
                            <div className="woller-match-wrapper">
                                <div>
                                    <img src={woller.get("UserPic") ? woller.get("UserPic").url() : require("../../assets/jober-icon.svg")} className="woller-match-icon"/>
                                    {woller.get("Level") > 1 && (
                                        <img
                                            className="medal-image"
                                            src={woller.get("Level") === 2
                                                    ? require("../../assets/badge-silver.svg")
                                                    : woller.get("Level") === 3
                                                    ? require("../../assets/badge-gold.svg")
                                                    : woller.get("Level") === 4
                                                    ? require("../../assets/badge-platinum.svg")
                                                    : null}
                                            alt="medal"
                                        />
                                    )}
                                </div>
                                <div className="woller-match-details">
                                    <p className="woller-match-name">{woller.get("Name")}</p>
                                    <p>{woller.get("Phone")}</p>
                                </div>
                                <div className="woller-match-details">
                                    <p>F.Creación: {formatDateWhitoutHour(woller.get("createdAt").toString())}</p>
                                    <p>U.conexion: {formatDateWhitoutHour(woller.get("Updated") ? woller.get("Updated").toString() : "Ningún registro")}</p>
                                </div>
                                <div className="woller-match-details">
                                    <p>Saldo: {woller.get("nJobs")}</p>
                                    {woller.get("Business") && (
                                        <p>B: {woller.get("Business").get("Name")}</p>
                                    )}
                                </div>
                                <button className={(job.get("JobType") == "lead" && woller.get("nJobs") < job.get("JobLeadPrice")) ? "woller-match-button-red" : "woller-match-button-blue"} onClick={() => selectJober(woller)}>
                                    {job.get("JobType") == "lead" ? `Precio ${job.get("JobLeadPrice")}€` : "Seleccionar"}
                                </button>
                            </div>
                        ))}
                    </div>
                )
            }
        </MainModal>
    )
}

export default MatchJobWollerModal;