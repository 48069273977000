import React, { useState } from "react";

import { Input, RadioButton, Button } from 'jobin-ui-components';
import { useStateValue } from "../../../state/context";

import "../../../styles/sign-up-data.css"

export default function SignUpData(props) {
    const style = {
        section:{
            display: 'flex',
            flex: 1,
        },
        halfSection:{
            display: 'flex',
            position: 'relative',
            flex: 1,
            flexDirection: 'column',
            height: 'calc(100vh - 72px)'
        },
        leftView:{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10%',
            marginRight: '10%',
            marginTop: 20
        },
        rightView:{
            display: 'flex',
            flexDirection: 'column',
        },
        inputSection:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: 24
        },
        inputView:{
            display: 'flex',
            flex: 1,
            marginBottom: 12
        },
        radioButtonSection:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: 24
        },
        bottomBar:{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            paddingBottom: 12,
            paddingTop: 12,
            width: '100%',
            borderTop: '1px solid var(--light-grey)',
            background: '#fff'
        }
    }

    const [options, setOptions] = useState([
        {
            id: "jober",
            title: "Registrar como Woller",
            selected: true
        },
        {
            id: "user",
            title: "Registrar como usuario",
            selected: false
        }
    ]);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [DNI, setDNI] = useState(null);
    const [phoneOk, setPhoneOk] = useState(true);
    const [DNIOk, setDNIOk] = useState(true);
    const [email, setEmail] = useState(null);
    const [emailOk, setEmailOk] = useState(true);
    const [level, setLevel] = useState(null);
    const [leadsFree, setLeadsFree] = useState(null);
    const [description, setDescription] = useState(null);

    const [data, dispatch] = useStateValue();

    const handleChange = id => {
        setOptions(options.map( temp => ({
            ...temp,
            selected: temp.id === id
        })));
    };

    const onDescriptionChange = input =>{
        setDescription(input);
    }

    const onNameChange = input =>{
        setName(input);
    }

    const onLevelChange = event =>{
        setLevel(event.target.value.replace(/[^\w\s]/gi, "").replace(/^[a-z,A-Z]/, ""));
    }

    const onLeadsFreeChange = event => {
        console.log(event.target.value)
        if(event.target.value < 11 || data.agent.dept == "CEO" || data.agent.agentId == "HhjJLJTauL"){
            setLeadsFree(event.target.value.replace(/[^\w\s]/gi, "").replace(/^[a-z,A-Z]/, ""));
        } else {
            alert("No se pueden poner mas de 10 leads gratuitos");
        }
    };

    const onPhoneChange = input =>{
        if (/^[0-9]{9}$/.test(input)) {
            setPhone(input);
            setPhoneOk(true);
        }
        else{
            setPhone(null);
            setPhoneOk(false);
        }
    }
    const onDNIChange = input =>{
        if (/^[a-zA-Z0-9]{6,10}$/.test(input)) {
            setDNI(input);
            setDNIOk(true);
        }
        else{
            setDNI(null);
            setDNIOk(false);
        }
    }

    const onEmailChange = input =>{
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(input)) {
            setEmail(input);
            setEmailOk(true);
        }
        else{
            setEmailOk(false);
            setEmail(null);
        }
    }

    return (
        <section style={style.section}>
            <div style={style.halfSection}>
                <div style={style.leftView}>
                    <h4>Datos del usuario</h4>
                    <label>Introduce los datos personales del nuevo usuario. Estos datos le serás necesarios para iniciar sesión posteriormente</label>
                    <div style={style.inputSection}>
                        <div style={style.inputView}>
                            <Input
                                style={{display: 'flex', flex: 1, marginRight: 12}}
                                placeholder="Nombre*"
                                onChange={(e)=>onNameChange(e.target.value)}
                            />
                            <Input
                                style={{display: 'flex', flex: 1,marginRight: 12}}
                                placeholder="Teléfono*"
                                onChange={(e)=>onPhoneChange(e.target.value)}
                                badInput={!phoneOk}
                            />
                            <Input
                                style={{display: 'flex', flex: 1}}
                                placeholder="DNI*"
                                onChange={(e)=>onDNIChange(e.target.value)}
                                badInput={!DNIOk}
                            />
                        </div>
                        <div style={style.inputView}>
                            <input 
                                value={level}
                                placeholder="Nivel (1,2,3,4)" 
                                onChange={onLevelChange} 
                                className="inputText"
                            />
                            
                            <input 
                                value={leadsFree}
                                placeholder="Leads gratuitos" 
                                onChange={onLeadsFreeChange} 
                                className="inputText"
                            />
                        </div>
                        <Input
                            placeholder="Email*"
                            onChange={(e)=>onEmailChange(e.target.value)}
                            badInput={!emailOk}
                        />
                        <Input
                            style={{marginTop: 12}}
                            placeholder="Descripcion"
                            onChange={(e)=>onDescriptionChange(e.target.value)}
                        />
                    </div>
                    <div style={style.radioButtonSection}>
                        {options.map((item) =>{
                            return(
                                <RadioButton
                                    key={item.id}
                                    style={{marginTop: 8}}
                                    item={item}
                                    onClick={() => handleChange(item.id)}
                                />
                            )
                        })}
                    </div>
                </div>
                <div style={style.bottomBar}>
                    <Button
                        buttonText="Siguiente"
                        onClick={() => props.nextView("data", {name: name, phone: phone,nif:DNI, email: email, level: level, leadsFree: leadsFree,
                             description: description, type: options.find( option => option.selected).id })}
                        disabled={props.loading || !(name && phone && (email || options.find( option => option.selected).id == "user"))}
                        loading={props.loading}
                    />
                </div>
            </div>
            <div style={style.halfSection}/>
        </section>
    );
}
