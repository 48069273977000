import React from "react";
import Jobin from "jobin-client";

export default function HistoryCell(props) {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            border: "1px solid var(--soft-grey)",
            borderRadius: 4,
            padding: 16,
            marginTop: 6,
            marginBottom: 6,
            backgroundColor: "#fff",
            boxShadow: "5px 5px 5px 0px var(--soft-grey)"
        },
        titleContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginBottom: 4
        },
        title: {
            flex: 1,
            fontSize: 13,
            color: "var(--greyish)"
        },
        userContainer: {
            display: "flex",
            flex: 1,
            marginTop: 12,
            alignItems: "center"
        },
        userLabel: {
            fontSize: 13
        },
        userPicView: {
            height: 30,
            width: 30,
            backgroundColor: "var(--soft-grey)",
            borderRadius: 15,
            marginRight: 8,
            border: 0
        }
    };

    const CRMUserName = props.history.user
        ? props.history.user.get("Name") +
          " " +
          props.history.user.get("Lastname")
        : "Desconocido";
    const CRMUserPic = props.history.user
        ? props.history.user.get("Image")
            ? props.history.user.get("Image").url()
            : require("../../../../assets/user-default-pic.svg")
        : require("../../../../assets/user-default-pic.svg");

    return (
        <section style={style.container}>
            <div style={style.titleContainer}>
                <label style={style.title}>{props.history.title}</label>
                <label style={{ ...style.title, textAlign: "right" }}>
                    {Jobin.Date.toSortFormat(props.history.createdAt).result}
                </label>
            </div>
            <label dangerouslySetInnerHTML={{__html: props.history.description}} />
            <div style={style.userContainer}>
                <img style={style.userPicView} src={CRMUserPic} alt="crmUserPic"/>
                <label style={style.userLabel}>{CRMUserName}</label>
            </div>
        </section>
    );
}
