import React, { useEffect,useState } from "react";
import { useStateValue } from "../../state/context";
import { formatCurrency } from "../../utils/formatter";
import { levelArr } from "../../constants/levels";
import { provinceArr } from "../../constants/provinces";
import Select from 'react-select';
import { useDebounce } from "../../utils/hooks";




import {findJobsforBiPro as findJobsforBiPro_ , getFavoritePacks as getFavoritePacks_} from "../../utils/ParserUtilities";

import SearchNav from "../../components/explore/SearchNav.jsx";
import MapCard from "../../components/explore/MapCard.jsx";
import JobCard from "../../components/explore/JobCard.jsx";
import RechargeMoneyModal  from "../../components/modal/RechargeMoneyModal.jsx";
import ServiceDetails  from "../../components/modal/ServiceDetails.jsx";
import ModalEventsBiPro  from "../../components/modal/ModalEventsBiPro";
import LevelUpModal  from "../../components/modal/LevelUpModal";

import vault from "../../assets/emptyVault.png"
import goldBadje from "../../assets/badgeGold.png"
import colors from "../../assets/colorsExplosion.png"


import "../../styles/explore/explore.css";

export default function Explore(props) {
    const [{explore,currentSession,general,user}, dispatch] = useStateValue();

    const [showLevelUpModal, setShowLevelUpModal] = useState(false)
    const [showServiceDetails, setShowServiceDetails] = useState(false)
    const [showMoneyModal, setShowMoneyModal] = useState(false)
    const [showBuyModal, setShowBuyModal] = useState(false)
    const [showFoundsModal, setShowFoundsModal] = useState(false)
    const [showLevelModal, setShowLevelModal] = useState(false)

    const [packs, setPacks] = useState([])
    const [filters, setFilters] = useState(false)
    const [sorteners, setSorteners] = useState(false)
    const [filterBy_,setFilterBy_] = useState("recent")
    const [jobSelected, setJobSelected] = useState(false);
    const [isForOwn, setIsForOwn] = useState(true);

    const [provinces, setProvinces] = useState([]);
    const [guilds, setGuilds] = useState([]);
    const [levels, setLevels] = useState([]);
    const [provinces_, setProvinces_] = useState();
    const [guilds_, setGuilds_] = useState();
    const [slots_, setSlots_] = useState();
    const [levels_, setLevels_] = useState();

    const debounceProvinces = useDebounce(provinces_, 1000);
    const debounceGuilds = useDebounce(guilds_, 1000);
    const debounceSlots = useDebounce(slots_, 1000);
    const debounceLevels = useDebounce(levels_, 1000);


    useEffect(()=>{
        if(explore.services.length < 1){
            findJobsforBiPro({})
        }
        getFavoritePacks()
        processProvinces()
        processWollerGuild()
        processServiceLevels()
    },[])

    useEffect(()=>{
        if(debounceProvinces !== undefined){
            dispatch({
               type: "EXPLORE_FILTER_PROVINCES",
               provinces:debounceProvinces,
           });
           
            findJobsforBiPro({provinces:debounceProvinces});
        }
    },[debounceProvinces])
    useEffect(()=>{
        if(debounceGuilds !== undefined){
            dispatch({
               type: "EXPLORE_FILTER_GUILDS",
               guilds:debounceGuilds,
           });
           
            findJobsforBiPro({guilds:debounceGuilds});
        }
    },[debounceGuilds])
    useEffect(()=>{
        if(debounceSlots !== undefined){
            dispatch({
               type: "EXPLORE_FILTER_SLOTS",
               slots:debounceSlots,
           });
           
            findJobsforBiPro({slots:debounceSlots});
        }
    },[debounceSlots])
    useEffect(()=>{
        if(debounceLevels !== undefined){
            dispatch({
               type: "EXPLORE_FILTER_LEVELS",
               levels:debounceLevels,
           });
           
            findJobsforBiPro({levels:debounceLevels});
        }
    },[debounceLevels])


    const jobSlots = [
      
        {
            value: 1,
            label: "sin competencia",
        },
        {
            value: 3,
            label: "hasta 3 presupuestos",
        },
    ]
    const getFavoritePacks = async ()=>{
        let packs_ = await getFavoritePacks_()
        if(packs_ && packs_.length>0){
            packs_ = packs_.map((pak)=>{
                return {
                    id:pak.id,
                    freeFTBCredits:pak.get("FreeFTBLeads"),
                    freeLeads:pak.get("FreeLeads"),
                    image:pak.get("Image").url(),
                    credits:pak.get("Leads"),
                    popular:pak.get("Popular"),
                    price:pak.get("Price"),
                    priceId:pak.get("stripeId"),
                    }
            })
            setPacks(packs_)
        }
    }
    
    const findJobsforBiPro = ({
        startDate=undefined,
        endDate=undefined,
        provinces=undefined,
        guilds=undefined,
        slots=undefined,
        levels=undefined,
        distance=undefined,
        buy=false,
    })=>{
       
    dispatch({
        type: "EXPLORE_LOADING",
        loading: true,
    });
    let  jobsPromise = findJobsforBiPro_({
        startDate:startDate ? new Date(startDate): startDate === null? startDate:explore.startDate,
        endDate:endDate ? new Date(endDate): endDate === null? endDate:explore.endDate,
        provinces:provinces && provinces !== undefined? provinces:explore.provinces,
        guilds:guilds && guilds !== undefined? guilds:explore.guilds,
        slots:slots && slots !== undefined? slots:explore.slots,
        levels:levels && levels !== undefined? levels:explore.levels,
        distance: distance !== undefined  ? parseInt(distance):parseInt(explore.distance),
        wollerId:currentSession.wollerData.id
    })

    Promise.all([jobsPromise]).then(([results]) => {
        dispatch({
            type: "EXPLORE_RESULTS",
            services:results.services,
            count:results.count
        });
        dispatch({
            type: "EXPLORE_LOADING",
            loading: false,
        });
        if(buy){
            setShowBuyModal(true)
        }
    })
    .catch((error) => {
        dispatch({
            type: "EXPLORE_LOADING",
            loading: false,
        });
        
    })
    }
    const handleBuyFromModal =()=>{
        setShowServiceDetails(false)
        handleSucsesfullBuy()
    }
    const ShowIsStateModal = ()=>{
        showMoneyModal(true)
    }
    const handleSucsesfullBuy= async ()=>{
        await findJobsforBiPro({buy:true})
    }
    const filterBy =(criterya)=>{
        setFilterBy_(criterya)
        let sortedServices  
        if(criterya == "recent"){
            sortedServices = explore.services.sort((aServ, bServ)=>{
                console.log(aServ, bServ)
                return aServ.createdAt.getTime() - bServ.createdAt.getTime() 
            })
            sortedServices.reverse()
        }else if(criterya == "closer"){
            sortedServices = explore.services.sort((aServ, bServ)=>{
                return aServ.distanceFormWoller - bServ.distanceFormWoller 
            })
        }else if(criterya == "cheap"){
            sortedServices = explore.services.sort((aServ, bServ)=>{
                return parseInt(aServ.jobLeadPrice) - parseInt(bServ.jobLeadPrice) 
            })
        }
        dispatch({
            type:"EXPLORE_RESULTS",
            services:sortedServices
        })
    }

    const processProvinces = async () => {
        let formatedProvinces = provinceArr.map( province => {
           return {
                value: province.id,
                label: province.name
            }
        })
        setProvinces(formatedProvinces);
    }
    const processWollerGuild = async () => {
        let formatedGuilds = general?.guilds.map( guild => {
            return {
                value: guild.id,
                label: guild.name
            }
        })
        setGuilds(formatedGuilds);
    }
    const processServiceLevels = async () => {
        let formatedLevels = levelArr.map( level => {
            return {
                value: parseInt(level.id),
                label: level.name
            }
        })
        setLevels(formatedLevels);
    }
  
    const showSelectedProvinces=(data)=>{
        data = data.map(selected=>{
            if(selected.value !== "none"){
                return selected.value
            }
        }).filter(x=>x)
        
        setProvinces_(data)
    }
    const showSelectedGuilds=(data)=>{
        data = data.map(selected=>{
            if(selected.value !== "none"){
                return selected.value
            }
        }).filter(x=>x)
        
        setGuilds_(data)
    }
    const showSelectedSlots=(data)=>{
        data = data.map(selected=>{
            if(selected.value !== "none"){
                return selected.value
            }
        }).filter(x=>x)
        
        setSlots_(data)
    }
    const showSelectedLevels=(data)=>{
        data = data.map(selected=>{
            if(selected.value !== "none"){
                return selected.value
            }
        }).filter(x=>x)
        
        setLevels_(data)
    }

    return (
        <div className="exploreContainer">
            {showLevelUpModal && <LevelUpModal
                isVisible={showLevelUpModal}
                onClose={()=>{setShowLevelUpModal(false)}}
            />}
            {showServiceDetails && <ServiceDetails
                isVisible={showServiceDetails}
                service={jobSelected}
                buySucesfull={setShowBuyModal} 
                lowFounds={setShowFoundsModal} 
                lowLevel={setShowLevelModal} 
                handleBuyFromModal={handleBuyFromModal}
                forOwn={isForOwn}
                onClose={()=>{setShowServiceDetails(false)}}
            />}
            {showMoneyModal && <RechargeMoneyModal
                isVisible={showMoneyModal}
                onClose={()=>setShowMoneyModal(false)}
                packs={packs}
            />}
            
            {showFoundsModal  && <ModalEventsBiPro // out of founds
                isVisible={ showFoundsModal}
                data={props.job}
                onClose={()=> setShowFoundsModal(false)}
                refreshJobs ={props.refresh}
                image = {vault}
                acction = {()=>{setShowFoundsModal(false);currentSession.showMoneyModal(true)}}
                title = {"¡VAYA!"}
                subTitle = {"NO TIENES SALDO SUFICIENTE..."}
                buttonText = {"¡RECARGA AHORA"}
                classNamesModal={{modal:"outFoundsModal"}}
            />}
            { showLevelModal && <ModalEventsBiPro // insuficent level
                isVisible={ showLevelModal}
                data={props.job}
                onClose={()=>{setShowLevelModal(false);}}
                refreshJobs ={props.refresh}
                image = {goldBadje}
                acction = {()=>{setShowLevelUpModal(true);setShowLevelModal(false)}}
                title = {"Ups... Aún no eres profesional ORO"}
                subTitle = {"¿Quieres convertirte en oro?"}
                buttonText = {"MÁS INFORMACIÓN"}
                classNamesModal={{modal:"insuficentLevelModal"}}
            />}
            {showBuyModal && <ModalEventsBiPro // pay success
                isVisible={ showBuyModal}
                data={props.job}
                onClose={()=> setShowBuyModal(false)}
                refreshJobs ={props.refresh}
                image = {colors}
                acction = {()=>{setIsForOwn(false);setShowServiceDetails(true);setShowBuyModal(false)}}
                title = {"¡¡ENHORABUENA!!"}
                subTitle = {"SERVICIO CONSEGUIDO"}
                buttonText = {"VER DETALLES DEL TRABAJO"}
                classNamesModal={{modal:"successModal"}}
                campain={jobSelected ? (jobSelected.type == "Fontanero" || jobSelected.type == "Climatización" || jobSelected.type == "Electrodomésticos") ? true : undefined : undefined}
            />}
            <div className={filters ?"headerMobile active":"headerMobile"}>
                <div  onClick={()=>{setFilters(!filters);setSorteners(false)}} className="mobieHeaderButton">
                    Filtros
                </div>
                <div  onClick={()=>{setSorteners(!sorteners);setFilters(false)}} className="mobieHeaderButton">
                    Ordenar
                </div>
                <div id="side-maps" onClick={()=>props.history.push({pathname: "/maps",state: {inner: true}})} className="mobieHeaderButton">
                    Mapa
                </div>
                <div className={filters ? "acordeon filters active":" acordeon filters "}>
                    <div className="select-explore-mobile-wrapper">
                        <p className="select-explore-mobile-title">Provincias:</p>
                        <Select
                            isMulti
                            options={provinces}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            defaultValue={provinces_}
                            onChange={showSelectedProvinces} 
                        />
                    </div>
                    <div className="select-explore-mobile-wrapper">
                        <p className="select-explore-mobile-title">Gremios:</p>
                        <Select
                            isMulti
                            options={guilds}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            defaultValue={guilds_}
                            onChange={showSelectedGuilds} 
                        />
                    </div>
                    <div className="select-explore-mobile-wrapper">
                        <p className="select-explore-mobile-title">Puestos de trabajo:</p>
                        <Select
                            isMulti
                            options={jobSlots}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            defaultValue={slots_}
                            onChange={showSelectedSlots} 
                        />
                    </div>
                    <div className="select-explore-mobile-wrapper">
                        <p className="select-explore-mobile-title">Nieveles de trabajo:</p>
                        <Select
                            isMulti
                            options={levels}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            defaultValue={levels_}
                            onChange={showSelectedLevels} 
                        />
                    </div>
                </div>
                <div className={sorteners ? "acordeon sorters active":" acordeon sorters "}>
                    <div onClick={()=>filterBy("recent")} className={filterBy_ == "recent" ? "buttonFilter active":"buttonFilter"}>Reciente</div>
                    <div onClick={()=>filterBy("closer")} className={filterBy_ == "closer" ? "buttonFilter center active":"buttonFilter center"}>Cercanos</div>
                    <div onClick={()=>filterBy("cheap")} className={filterBy_ == "cheap" ? "buttonFilter active":"buttonFilter"}>Baratos</div>
                </div>
            </div>
            <div className="headerInfo">
                <p>{explore?.count} trabajos en tu zona</p>
                <p>Tu saldo disponible:</p>
                {currentSession.wollerData?.get("nJobs") && <button onClick={()=>setShowMoneyModal(true)} className={user.isUserSubscription ? "balanceButton infinite":"balanceButton"}> {user.isUserSubscription ? "∞":formatCurrency(currentSession.wollerData?.get("nJobs"))}</button>}
            </div>

            <MapCard findJobsforBiPro={findJobsforBiPro}/>
            <div className="filltersContainer">
                <SearchNav findJobsforBiPro={findJobsforBiPro}/>
                <JobCard 
                    buySucesfull={setShowBuyModal} 
                    lowFounds={setShowFoundsModal} 
                    lowLevel={setShowLevelModal} 
                    ownedService={setJobSelected}
                    handleSucsesfullBuy={handleSucsesfullBuy}
                    serviceDetails={setShowServiceDetails}
                    setIsForOwn ={setIsForOwn}
                />
            </div>
        </div>
    );
    
}




