import React, { useEffect, useState } from "react";
import moment from "moment";
import { DayPickerRangeController} from "react-dates";

import "../../styles/multiSelect.css";

export default function RangeDatePicker({title,handleDateSelected}) {
    const [date, setDate] = useState({
        startDate: undefined,
        endDate: undefined
    });
    const [focused, setFocused] = useState("startDate");
    const [active, setActive] = useState(false);
    const [focusedStart, setFocusedStart] = useState(false);
    const [focusedEnd, setFocusedEnd] = useState(false);

    useEffect(()=>{
        if(date.endDate != null){
            handleDateSelected(date)
        }
    },[date])
    const filterDate = (startDate, endDate) => {
        setDate({
            startDate: startDate ? startDate.utc().startOf("day") : null,
            endDate: endDate ? endDate.utc().endOf("day") : null
        });
       
    };
    const onFocusChangeDate = focusedInput => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocused(!focusedInput ? "startDate" : focusedInput);
    };
    const onFocusChangeDateStart = () => {
        console.log("ss")
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocusedStart(!focusedStart);
    };
    const onFocusChangeDateEnd = () => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocusedEnd(!focusedEnd);
    };
  return (
    <div className="rangeDatePickerContainer">
        <p className="dateRangeTitle">{title}</p>
        <div onClick={()=>setActive(!active)} className="datePromt">{`${date !== undefined && date.startDate ? date.startDate.format('DD/MM/YYYY'):""} - ${date !== undefined && date.endDate ? date.endDate.format('DD/MM/YYYY'):""}`}</div>
       {active && 
        <DayPickerRangeController
            onDatesChange={({ startDate, endDate }) =>
                filterDate(startDate, endDate)
            }
            onFocusChange={onFocusChangeDate}
            focusedInput={focused}
            startDate={date.startDate}
            endDate={date.endDate}
            numberOfMonths={2}
            minDate={moment('2021-01-01')}
            hideKeyboardShortcutsPanel={true}
        />
       } 
    </div>
  
  
  
  
  )
}
