import React, { useEffect, useState, useRef } from "react";
import Jobin from "jobin-client";
import GoogleMapReact from "google-map-react";
import CurrencyInput from 'react-currency-input-field'; 
import { useStateValue } from "../../../../state/context";

import InfoCell from "../InfoCell";
import EditExtrasModal from "../../../modal/EditExtrasModal";

import SelectBusinessModal from "../../../modal/SelectBusinessModal";
import SelectAgentModal from "../../../modal/SelectAgentModal";
import Button from "../../../button/Button";
import {editJobData, getSugestedPrice,updateVerified} from  "../../../../utils/ParserUtilities";
import ShowPhotosModal from "../../../modal/ShowPhotosModal"

import Parse from 'parse';
import { apiId, jsKey, urlServer  } from '../../../../constants/credentials';
import {formatState, formatDateWhitoutHour} from '../../../../utils/formatter'
import "../../../../styles/search/detail/job-detail-container.css";
import VerticalModal from "../../../modal/VerticalModal";

export default function DetailContainer(props) {
    const [data, dispatch] = useStateValue();
    const [{ user,currentSession, agent }] = useStateValue();
    
    const [includeArray, setIncludeArray] = useState([]);
    const [nonIncludeArray, setNonIncludeArray] = useState([]);
    const [fixedInfo, setFixedInfo] = useState(null);
    const [transportInfo, setTransportInfo] = useState(null);
    const [relatedJob, setRelatedJob] = useState(null);
    const [business, setBusiness] = useState(null);
    const [newAgent, setAgent] = useState(null);
    const [shopLocation, setShopLocation] = useState(null);
    const [showBusiness, setShowBusiness] = useState(false);
    const [showAgent, setShowAgent] = useState(false);
    const [showVerticalModal, setShowVerticalModal] = useState(false);
    const [typeService, setTypeService] = useState(null);
    const [optionsService, setOptionsService] = useState([]);
    const [jobdId, setJobId] = useState(null);
    const [descriptionJobs, setDescriptionJobs] = useState(null);
    const [sugestedPrice, setSugestedPrice] = useState(null);
    const [verified, setVerified] = useState(false);
    const [fixedPhotos, setFixedPhotos] = useState(null);
    const [showPhotos, setShowPhotos] = useState(false);
    const [photosToShow, setPhotosToShow] = useState([]);
    const [isCopied, setIsCopied] = useState(false);

    const [showEditExtrasModal, setShowEditExtrasModal] = useState(false);
    const [extrasArray, setExtrasArray] = useState(data.jobDetails.extrasArray);
    const [jobWollerNotification, setJobWollerNotification] = useState(undefined);



    useEffect(() => {
    
        if (data.jobDetails.transportInfo) {
            async function getTransportInfo() {
                const vertical = data.jobDetails.transportInfo.get("Vertical");
                const result = await Jobin.Guild.getFromId(vertical.id);
                if (result.status === "ok") {
                    setShopLocation(result.result.get("Location"));
                }
            }
            if (data.jobDetails.transportInfo.get("RelatedTo")) {
                async function getRelatedJob() {
                    const result = await Jobin.Job.getRelatedJob(
                        data.jobDetails.transportInfo
                    );
                    if (result.status === "ok") {
                        setRelatedJob(result.result);
                    }
                }
                getRelatedJob();
            }
            getTransportInfo();
            setTransportInfo(data.jobDetails.transportInfo);
        } else if (data.jobDetails.fixedInfo) {
            async function getIncludes() {
                const result = await Jobin.Job.getIncludes(data.jobDetails.job);
                if (result.status === "ok") {
                    setIncludeArray(result.result);
                }
            }
            async function getNonIncludes() {
                const result = await Jobin.Job.getNonIncludes(
                    data.jobDetails.job
                );
                if (result.status === "ok") {
                    setNonIncludeArray(result.result);
                }
            }
            if (data.jobDetails.fixedInfo.get("RelatedTo")) {
                async function getRelatedJob() {
                    const result = await Jobin.Job.getRelatedJob(
                        data.jobDetails.fixedInfo
                    );
                    if (result.status === "ok") {
                        setRelatedJob(result.result);
                    }
                }
                getRelatedJob();
            }
            getIncludes();
            getNonIncludes();
            setFixedInfo(data.jobDetails.fixedInfo);
        }

        async function getBusiness() {
            const business = data.jobDetails.job.get("Business");
            if (business) setBusiness(await business.fetch());
            let leadPrice = await getSugestedPrice(data.jobDetails.job.id)
            setSugestedPrice(leadPrice)
        }
        getBusiness();
        if (data.jobDetails.job.has("AssignedTo")) {
            data.jobDetails.job.get("AssignedTo").fetch().then(newAgent => setAgent(newAgent));
        }
        setTypeService(data.jobDetails.job.get("Type"));
        setDescriptionJobs(data.jobDetails.job.get("Description"));
     
        
        getAllJobs();
        getFatherVerticals();
        setJobId(data.jobDetails.job.id);
        setVerified(data.jobDetails.job.get("Verified"));

        if(user.isGoldWoller){
            checkIfWollerBuyJob(data.jobDetails.job.id);
        }

        getNotificationsPhotos(data.jobDetails.job.id);

    }, []);

    useEffect(()=>{
        if(photosToShow.length > 0) setShowPhotos(true);
    },[photosToShow])

    const saveExtras = async extraArr => {
        setShowEditExtrasModal(false);
        setExtrasArray(extraArr);

        const extraPrice = extraArr.reduce((accumulator, extra) => {
            return accumulator + extra.pricePerUnit * extra.units;
        }, 0);

        dispatch({
            type: "JOB_PRICE_EXTRAS",
            extrasArray: extraArr,
            extraAmount: extraPrice
        });
    };

    const title = () => {
        const jobType = data.jobDetails.job.get("JobType");
        switch (jobType) {
            case "lead":
                return "Lead";
            case "fixed":
                return "Precio cerrado";
            case "transport":
                return "Transporte";
            case "bid":
                return "Subasta";
            default:
                return "Trabajo";
        }
    };

    const handleGoogleMapApi = google => {
        let directionsService = new google.maps.DirectionsService();
        var directionsDisplay = new google.maps.DirectionsRenderer();
        directionsDisplay.setMap(google.map);

        directionsService.route(
            {
                travelMode: "DRIVING",
                origin: new google.maps.LatLng(
                    shopLocation.latitude,
                    shopLocation.longitude
                ),
                destination: new google.maps.LatLng(
                    transportInfo.get("Location").latitude,
                    transportInfo.get("Location").longitude
                )
            },
            (DirectionsResult, DirectionsStatus) => {
                if (DirectionsStatus === "OK") {
                    directionsDisplay.setDirections(DirectionsResult);
                }
            }
        );
    };

    const getAllJobs = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getAllJobs");
        // let arrayServices = [];
        // res.map(element => {
        //     if(arrayServices.includes(element.get("Type")) == false){
        //         arrayServices.push(element.get("Type"))
        //     }
        // })
        // setOptionsService(arrayServices);
    }

    const getFatherVerticals = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getFatherVerticals");
        let arrayServices = [];
        res.map(element => {
                arrayServices.push(element.get("Type"))
        })
        setOptionsService(arrayServices);
    }

    const getNotificationsPhotos = async (id) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getNotificationsPhotos", {jobId: id});
        setFixedPhotos(res)
    }
    
    const handleChangeSelect = async (event) => {
        setTypeService(event.target.value);
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let data = {
            id: jobdId,
            typeService: event.target.value,
            crmId: agent ? agent.agentId ? agent.agentId : null : null
        }
        let res = await Parse.Cloud.run("updateTypeJobs", data);
    }

    const checkIfWollerBuyJob = async (id) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let data = {
            jobId: id,
            userId: currentSession?.wollerData?.id
        }
        let res = await Parse.Cloud.run("checkIfWollerBuyJob", data);
        if(res){
            setJobWollerNotification(res);
        }
    }

    const handleChangeSelectVerified = async (event) => {
        
        let res = await updateVerified(jobdId, event.target.value == "true" ? true :  false);
    }

    const handleChangeDescription = async (event) => {
        setDescriptionJobs(event.target.value);
    }
    const handleChangeSugestedPrice = async (value) => {
         setSugestedPrice(value);
    }

    const saveChangeDescription = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.User.enableUnsafeCurrentUser();
        let data = {
            id: jobdId,
            description: descriptionJobs
        }
        let res = await Parse.Cloud.run("updateDescription", data);
    }

    const saveChangeSugestedPrice = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.User.enableUnsafeCurrentUser();
        let newData = {
            jobId: jobdId,
            sugestedPrice: sugestedPrice
        }
        //modificacion especial eliminar todos los agentId diferentes a  HhjJLJTauL
        if(sugestedPrice >= 3 || data.agent.dept == "CEO" || 
        data.agent.agentId == "HhjJLJTauL"|| 
        data.agent.agentId == "mkmHlYHisJ"|| 
        data.agent.agentId == "dDDC1Hd530"|| 
        data.agent.agentId == "EKtCUAxwTK"|| 
        data.agent.agentId == "gsvYzRYYaJ"|| 
        data.agent.agentId == "jAYoHh1ckL"|| 
        data.agent.agentId == "5dQhlwhprl" ||
        data.agent.agentId == "vQD7dZqdfL" ||
        data.agent.agentId == "gdMnCpCtux"){
            let res = await Parse.Cloud.run("updateSugestedPrice", newData);
        } else {
            alert("No se puede cambiar el precio de un trabajo menor a 3 euros");
        }
    }

    const selectBusiness = async business => {
        setBusiness(business);
        setShowBusiness(false);
        const result = await Jobin.Business.setBusinessForJob(
            business,
            data.jobDetails.job
        );
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: await data.jobDetails.job.fetch(),
            });
        } else {
            setBusiness(null);
            setShowBusiness(false);
            alert(result.result);
        }
    };

    const selectAgent = async agent => {
        const {status, result} = await Jobin.Job.reassignJob(
            data.jobDetails.job,
            agent
        );
        if (status === "ok") {
            setAgent(agent);
            setShowAgent(false);
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: await data.jobDetails.job.fetch(),
            });
        } else {
            setAgent(null);
            setShowAgent(false);
            alert(result);
        }
    };

    const handleTexChange = async (event) => {
        let dataParsed={
            key:event.target.name,
            value:event.target.value
        }

        let response = await editJobData (dataParsed, data.jobDetails.job.id)
    };
    const removeFile = async e => {
        const filename = e.target.getAttribute("data-filename");
        await Jobin.Job.removeFileFromJob(data.jobDetails.job.id, filename);
        window.location.reload();
    };

    return (
        <section className="jobDetailContainer">
            {showPhotos && (
                    <ShowPhotosModal
                        isVisible={true}
                        onClose={() => setShowPhotos(false)}
                        photos={photosToShow}
                        title={"Fotos"}
                    />
                )}
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    overflow: "hidden"
                }}
            >
                <label className="subTitle">
                    {!user?.isGoldWoller && (<label onClick={() => setShowVerticalModal(true)} style={{ color: '#3543FF', cursor: 'pointer' }}></label>)}
                    <label>{ title() }</label>
                    {
                        showVerticalModal && (
                            <VerticalModal
                                onClose={() => setShowVerticalModal(false)}
                            />
                    )}
                </label>
                <label className="title">
                    {data.jobDetails.job.get("Title")}
                </label>
                {
                    !user?.isMediator && (
                        <p>Gremio:</p>
                    )
                }
                <div>
                    {data.user.permissions && data.user.permissions.job.edit ?(<select 
                        value={typeService} 
                        disabled={user?.isGoldWoller}
                        onChange={!user?.isGoldWoller ? handleChangeSelect:null}
                        style={{
                            width: '100%',
                            padding: '5px',
                            border: '1px solid #E3E3E3',
                            borderRadius: '4px'
                        }}
                    >  
                        {
                            optionsService.map(element => {
                                return (<option key={element}>{element}</option>)
                            })
                        }
                        
                    </select>):
                    (<h5 className="margin-top-0 ">{typeService}</h5>)}
                </div>
                {
                    !user?.isMediator && (
                        <p>Verified:</p>
                    )
                }
                <div>
                    {data.user.permissions && data.user.permissions.job.edit ?(<select 
                        value={verified} 
                        disabled={user?.isGoldWoller }
                        onChange={!user?.isGoldWoller ? handleChangeSelectVerified :null}
                        style={{
                            width: '100%',
                            padding: '5px',
                            border: '1px solid #E3E3E3',
                            borderRadius: '4px'
                        }}
                    >  
                        <option key={true}>{'true'}</option>
                        <option key={false}>{'false'}</option>
                    </select>):
                    (<h5 className="margin-top-0 ">{verified}</h5>)}
                </div>
            </div>
            <div className="detailContainer">
                <InfoCell
                    title="ID del trabajo"
                    data={
                        fixedInfo
                            ? fixedInfo.get("ID")
                            : transportInfo
                            ? transportInfo.get("ID")
                            : data.jobDetails.job.id
                    }
                    halfLeft={true}
                />
                <InfoCell
                    title="Estado del trabajo"
                    data={formatState(data.jobDetails.job)}
                    halfRight={true}
                />
            </div>
            <InfoCell
                title="Negocio asignado"
                data={business ? business.get("Name") : "Sin negocio asignado"}
                logo={
                    business && business.get("Logo")
                        ? business.get("Logo").url()
                        : null
                }
                optionText="Editar"
                optionAction={!user?.isGoldWoller && user?.permissions?.job.edit ?() => setShowBusiness(true):null}
            />
            {
                jobWollerNotification && (
                    <InfoCell
                        title="Telefono"
                        data={jobWollerNotification.get("ToUser").get("Phone")}
                    />
                )
            }
            {showBusiness && (
                <SelectBusinessModal
                    isVisible={showBusiness}
                    onClose={() => setShowBusiness(false)}
                    selectBusiness={selectBusiness}
                />
            )}
            {!user?.isMediator && (
                <InfoCell
                    title="Agente asignado"
                    data={
                        newAgent && newAgent.has("Lastname")
                            ? newAgent.get("Name") + ' ' + newAgent.get("Lastname")
                            : newAgent
                            ? newAgent.get("Name")
                            : "Sin agente asignado"
                    }
                    optionText="Editar"
                    optionAction={!user?.isGoldWoller &&user?.permissions?.job.assign ? () => setShowAgent(true) : undefined}
                />
            )}
            {showAgent && (
                <SelectAgentModal
                    isVisible={showAgent}
                    onClose={() => setShowAgent(false)}
                    selectAgent={selectAgent}
                />
            )}
            {data.jobDetails.job.get("Priority") === "urgent" &&
                <InfoCell
                    title="Urgencia"
                    data={"Servicio urgente"}
                    logo={
                        require("../../../../assets/urgent-selected.svg")
                    }
                />
            }
            <div className="detailContainer">
                <InfoCell
                    title="Nivel"
                    data={
                        data.jobDetails.job.get("Level") === 4
                            ? "Platino"
                            : data.jobDetails.job.get("Level") === 3
                            ? "Oro"
                            : data.jobDetails.job.get("Level") === 2
                            ? "Plata"
                            : "Bronce"
                    }
                    logo={
                        data.jobDetails.job.get("Level") === 4
                            ? require("../../../../assets/badge-platinum.svg")
                            : data.jobDetails.job.get("Level") === 3
                            ? require("../../../../assets/badge-gold.svg")
                            : data.jobDetails.job.get("Level") === 2
                            ? require("../../../../assets/badge-silver.svg")
                            : require("../../../../assets/badge-bronce.svg")
                    }
                    halfLeft
                />
                <InfoCell
                    title="Fecha de creación"
                    data={
                        Jobin.Date.toLargeFormat(data.jobDetails.job.createdAt)
                            .result
                    }
                    halfRight
                />
            </div>
            {fixedInfo && (
                <InfoCell
                    title="Cita preferible"
                    data={
                        fixedInfo.get("AvailableTo")
                            ? Jobin.Date.toSortFormat(
                                    fixedInfo.get("AvailableTo")
                                ).result
                            : "Sin fecha"
                    }
                />
            )}
            {relatedJob && (
                <InfoCell
                    title="Trabajo relacionado"
                    data={relatedJob.id}
                    optionText="Ver"
                    optionAction={() => {
                        props.history.push({
                            pathname: "/search/" + relatedJob.id,
                            search: props.location.search
                        });
                    }}
                />
            )}
            {transportInfo && (
                <div className="detailContainer">
                    <InfoCell
                        title="Distancia"
                        data={transportInfo.get("Distance") + " Km"}
                        halfLeft={true}
                    />
                    <InfoCell
                        title="Peso"
                        data={transportInfo.get("Weight") + " Kg"}
                        halfRight={true}
                    />
                </div>
            )}
            <div className={["display-flex","flex-column"]} >
                <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Localización</label>
                    <input
                    disabled={user?.isMediator ? user?.isMediator : user?.isGoldWoller && user?.permissions?.job?.edit}
                    className="textInput"
                    type="text" 
                    style={{
                        width: '100%',
                        padding: '5px',
                        border: '1px solid #E3E3E3',
                        borderRadius: '4px',
                        marginRight:"auto"
                    }} 
                    defaultValue={data.jobDetails.job.get("Address") }
                    name="Address"
                    onBlur={handleTexChange}
                
                    ></input>
                    
                </div>
            <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px'}}>Descripción</label>
            <textarea
            style={{
                width: '100%',
                padding: '5px',
                border: '1px solid #E3E3E3',
                borderRadius: '4px',
                minHeight: '80px',
                resize: 'none'
            }} 
            disabled={user?.isMediator ? user?.isMediator : user?.isGoldWoller && user?.permissions?.job?.edit}
            value={descriptionJobs}
            onChange={handleChangeDescription}
            onBlur={saveChangeDescription}
            ></textarea>
           
            {data.jobDetails.job.get("JobType") == "lead" && (<section  style={{
                display:"flex",
                flexDirection: "column",
                paddingBottom: "8px",
                borderBottom: "1px solid var(--soft-grey)",
                overflow: "hidden"
                
                }}>
                {!user.isB2b && user?.permissions?.job?.create && !user?.isMediator && (
                <>
                    <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Precio</label>
                    <div style={{display:"flex"}} >
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder="Please enter a number"
                            defaultValue={0}
                            value={sugestedPrice}
                            intlConfig={{ currency: 'EUR' }}
                            decimalsLimit={2}
                            onValueChange={handleChangeSugestedPrice}
                            style={{
                                width: '82%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                marginRight:"auto"}
                            }
                            disabled={user?.isGoldWoller ? true : (data.user.permissions.job && !data?.user?.permissions?.job.edit)}
                        />
                    
                        {!user?.isGoldWoller && data.user.permissions.job && data?.user?.permissions?.job.edit && (<div className="buttonContainer">
                            <Button
                                buttonText="Editar"
                                style={{ width: 85, color: "var(--coral)" }}
                                onClick={saveChangeSugestedPrice}
                            />
                        </div>)}
                    </div>
                </>)}
            </section>)}
            {data.jobDetails.job.get("Images") && (
                <InfoCell
                    title="Imágenes"
                    imagesData={data.jobDetails.job.get("Images")}
                />
            )}
            {fixedInfo && (
                <div>
                    {fixedInfo && fixedInfo.get("Jober") && (
                        <div className="f-row" style={{marginTop: 10, marginBottom: 10, fontWeight: "bold"}}>
                            <p>URL para dar de alta imagenes</p>
                            <button className="fixedPhotos-copy" onClick={() => {navigator.clipboard.writeText(`https://www.wollyhome.com/wollers/#/upload-photos?${jobdId}?${fixedInfo.get("Jober").id}`); setIsCopied(true)}}>{!isCopied ? "Copiar!":"Copiado!"}</button>
                        </div>
                    )}
                    {fixedPhotos && fixedPhotos.preImages && fixedPhotos.preImages.length && (
                        <>
                            <div className="f-row">
                                <p className="fixedPhotos-title">Imgenes del antes (Woller)</p>
                                <button onClick={() => {setPhotosToShow(fixedPhotos.preImages)}} className="fixedPhotos-button">Ver Imagenes</button>
                            </div>
                            <p>{fixedPhotos.preImages.length} imagenes subida(s)</p>
                        </>
                    )}
                    {fixedPhotos && fixedPhotos.postImages && fixedPhotos.postImages.length && (
                        <>
                            <div className="f-row">
                                <p className="fixedPhotos-title">Imgenes del despues (Woller)</p>
                                <button onClick={() => {setPhotosToShow(fixedPhotos.postImages)}} className="fixedPhotos-button">Ver Imagenes</button>
                            </div>
                            <p>{fixedPhotos.postImages.length} imagenes subida(s)</p>
                        </>
                    )}
                    {fixedPhotos && fixedPhotos.signature && (
                        <>
                            <div className="f-row">
                                <p className="fixedPhotos-title">Firma del cliente proporcionada por el Woller</p>
                                <button onClick={() => {setPhotosToShow([fixedPhotos.signature])}} className="fixedPhotos-button">Ver Firma</button>
                            </div>
                            <p>Fecha de la firma cliente: {formatDateWhitoutHour(fixedPhotos.date.toString())} </p>
                        </>
                    )}
                    {fixedPhotos && fixedPhotos.signaturePro && (
                        <>
                            <div className="f-row">
                                <p className="fixedPhotos-title">Firma del Woller</p>
                                <button onClick={() => {setPhotosToShow([fixedPhotos.signaturePro])}} className="fixedPhotos-button">Ver Firma</button>
                            </div>
                            <p>Fecha de la firma Woller: {formatDateWhitoutHour(fixedPhotos.date.toString())} </p>
                        </>
                    )}
                    {fixedInfo.get("Extras") && (
                        <InfoCell
                            title="Extras"
                            dataArray={fixedInfo.get("Extras")}
                            optionText="Editar"
                            optionAction={data.user.permissions.job && data?.user?.permissions?.job.edit ? () => setShowEditExtrasModal(true) : undefined}
                        />
                        
                    )}
                    {includeArray && (
                        <InfoCell
                            title="¿Qué incluye?"
                            dataArray={includeArray}
                        />
                    )}
                    {nonIncludeArray && (
                        <InfoCell
                            title="¿Qué NO incluye?"
                            dataArray={nonIncludeArray}
                        />
                    )}
                    {fixedInfo.has("AttachedFiles") && (
                        <InfoCell
                            title="Archivos adjuntos"
                            optionAction={removeFile}
                            dataArray={fixedInfo
                                .get("AttachedFiles")
                                .map(file => {
                                    return {
                                        filename: file && file.hasOwnProperty('_name') ? file.name() : "-",
                                        url: file && file.hasOwnProperty('_url') in file ? file.url() : "-"
                                    };
                                })}
                        />
                    )}
                    <EditExtrasModal
                            isVisible={showEditExtrasModal}
                            onClose={() => setShowEditExtrasModal(false)}
                            extrasArray={extrasArray}
                            saveExtras={saveExtras}
                            allowDeleteItems={true}
                            /*subvertical={subvertical}*/
                            /*isUrgent={selectedOption === "urgent"}*/
                           /* basicPrice={job.price.amount}*/
                        />
                </div>
            )}
            {transportInfo && (
                <div>
                    {transportInfo.has("AttachedFiles") && (
                        <InfoCell
                            title="Archivos adjuntos"
                            optionAction={removeFile}
                            dataArray={transportInfo
                                .get("AttachedFiles")
                                .map(file => {
                                    return {
                                        filename: file && file.hasOwnProperty('_name') ? file.name() : "-",
                                        url: file && file.hasOwnProperty('_url') in file ? file.url() : "-"
                                    };
                                })}
                        />
                    )}
                </div>
            )}
            {transportInfo && shopLocation && (
                <div
                    style={{
                        height: "300px",
                        width: "100%",
                        borderRadius: 4,
                        overflow: "hidden",
                        marginTop: 8,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}
                >
                    <label
                        style={{
                            fontSize: 12,
                            color: "var(--greyish)"
                        }}
                    >
                        Mapa
                    </label>
                    <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={handleGoogleMapApi}
                        bootstrapURLKeys={{
                            key: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus"
                        }}
                        defaultZoom={12}
                        defaultCenter={{
                            lat: transportInfo.get("Location").latitude,
                            lng: transportInfo.get("Location").longitude
                        }}
                    />
                </div>
            )}
        </section>
    );
}
