import React, { useEffect, useState } from "react";

import MainModal from "./MainModal"
import AlertModal from "./Alert";

import { useStateValue } from "../../state/context";
import { formatCurrency } from "../../utils/formatter";
import { buyJobForWoller } from "../../utils/ParserUtilities";


import moment from "moment";
import "moment/locale/es";


import "../../styles/modal/service-details-modal.css";
import wrench from "../../assets/icons/wrench.svg"


const ServiceDetails = ({onClose,isVisible,service,lowFounds,lowLevel,forOwn,handleBuyFromModal,jobCard}) => {
    
    const [{currentSession, user}, dispatch] = useStateValue();
    const [jobSelected, setJobSelected] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [copyText, setCopyText] = useState("Compartir URL");

    const handleBuy = async (service)=>{
        if(parseInt(currentSession.wollerData.get("Level")) < parseInt(service.level)){
            lowLevel(true)
        }else if(currentSession.wollerData.get("nJobs") < parseInt(service.price)){
            lowFounds(true)
        }else{
            setJobSelected(service)
            setShowConfirmModal(true)
        }
    }
    const asignWoller = async (serviceId) => {
        let buy = await  buyJobForWoller(serviceId,currentSession.wollerData.id)
        console.log("compra")
        if (buy){
            setShowConfirmModal(false)
            handleBuyFromModal()
        } else  {
            alert(buy.error)
            setShowConfirmModal(false)
        }
    };

    const calculateSlots = (slots,takenSlots,serviceId)=>{
        let takenSlots_ = takenSlots 
        let slotsCalc = []
        for (let index = 0; index < parseInt(slots); index++) {
            if(takenSlots_ > 0){
                takenSlots_ = takenSlots_ - 1
                slotsCalc.push(<div key={"fullHelmet"+index+serviceId} className="helmet"><img src={require("../../assets/icons/Casco.png")} alt=""  /></div>)
            }else{
                slotsCalc.push(<div key={"emptyHelmet"+index+serviceId} className="empty"></div>)
            }
        }
        return slotsCalc
    }
    const fixedProcess = ()=>{
        window.open(`https://www.wollyhome.com/wollers/#/upload-photos?${service.id}?${currentSession.wollerData.id}`, "Diseño Web", "width=300, height=200")
    }
    const generateLink = ()=>{
        setCopyText("Copiado en el portapapeles")
        navigator.clipboard.writeText(`https://www.wollyhome.com/wollers/#/upload-photos?${service.id}?${currentSession.wollerData.id}`)
    }
    return (
        <MainModal
            classNamesModal={{modal:"ServiceDetailsModal"}}
			isVisible={isVisible}
			onClose={()=>onClose()}
            center={true}
		>
            {showConfirmModal && (<AlertModal
                        isVisible={showConfirmModal}
                        onClose={() => setShowConfirmModal(false)}
                        title={service.title}
                        subtitle="¿Seguro que deseas comprar este trabajo?"
                        buttonText="Comprar"
                        onClick = {()=>{asignWoller(service.id)}}
                        classNamesModal={{modal:"outFoundsModal"}}
                    />
                )}
            <div className="service-details-wrapper">
                <div className="service-details-data-wrapper">
                    <div className={service?.level == 2? "service-details-row-wrapper title Plata":service?.level == 3 ? "service-details-row-wrapper title Oro":service?.level == 4 ? "service-details-row-wrapper title Platino":"service-details-row-wrapper title Bronce"}>
                        <img className="service-detail-vertical-img"  src={service.vertical ? service.vertical?.get("Icon")?.url() ? service.vertical?.get("Icon")?.url() : "" : service.verticalLogo._url}/>
                        <span className="service-detail-level">{service?.level == 2? "Plata -":service?.level == 3 ? "ORO -":service?.level == 4 ? "Platino -" :"Bronce -"}</span>
                        <p className="service-details-vertical">{service.type}</p>
                    </div>

                    <div className="service-details-row-wrapper">
                        
                        <p className="service-details-title">{service.title}</p>
                    </div>
                    <div className="service-details-row-wrapper">
                        <img src={require("../../assets/icons/User.svg")} alt="" />
                        <p className="service-details-p">{service.createdBy ? service.createdBy?.get("Name") : service.clientName}</p>
                    </div>
                    <div className="service-details-row-wrapper">
                        <img src={require("../../assets/icons/MapPinLine.svg")} alt="" />
                        <p className="service-details-p">{service.address}</p>
                    </div>
                     <div className="service-details-row-wrapper">
                        <img  src={require("../../assets/icons/Phone.svg")} alt="" />
                        <p className="service-details-p">   {!service.createdBy && !forOwn? service.clientPhone :forOwn && service.createdBy?.get("Phone").length > 9 ? service.createdBy?.get("Phone").slice(0,9)+"***": forOwn ?  service.createdBy?.get("Phone").slice(0,6)+"***" :service.createdBy?.get("Phone")}</p>
                    </div>
                    <div className="service-details-row-wrapper">
                        <img src={require("../../assets/icons/Clock.svg")} alt="" />
                        <p className="service-details-p">Subido {moment(service?.createdAt).fromNow()}</p>
                    </div>
                    {
                        (service.business || service.businessLogo) && (
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/Suitcase.svg")} alt="" />
                                <img className="businessIcon" src={service.business ? service.business?.get("Logo")?.url() ? service.business?.get("Logo")?.url() : "": service.businessLogo ? service.businessLogo._url : ""}/>
                            </div>
                        )
                    }
                    {
                        (service.jobType == "fixed" && service.fixedPrice) && (
                            <div className="service-details-row-wrapper-fixed profit">
                                <span className="flofitTitle">Ganancia Directa:</span> <span className="profitData">{formatCurrency(service.fixedPrice)} + IVA</span>
                            </div>
                        )
                    }
                    <div className="buyAndSlots">

                        {forOwn && (service.jobType == "lead" || service.jobType== undefined) ? 
                            (<button onClick={()=>handleBuy(service)} className="service-details-buy-button">CONTACTAR POR {formatCurrency(service.jobLeadPrice) }</button>):
                        !forOwn && service.jobType == "fixed" ? 
                            (
                                <>
                                    <button onClick={()=>fixedProcess(service)} className="service-details-buy-button">INICIAR GESTIÓN</button>
                                    <button onClick={()=>generateLink(service)} className="service-details-buy-button-clear">{copyText}</button>
                                </>
                            )
                        :
                            (<a href={`tel:${service.createdBy?.get("Phone")}`} className="service-details-buy-button">CONTACTAR</a>)
                        }
                        {(service.jobType == "lead" || service.jobType== undefined) && <div className="slotsContainer">
                                {service?.totalSlots == 1 ? 
                                    (
                                        <>
                                            <img className="oneSlot" src={require("../../assets/n1.png")} alt="" />
                                            <p>SERVICIO SIN COPMPETENCIA</p>
                                        </>
                                    ) :(
                                        <>
                                            {calculateSlots(service?.totalSlots,service?.takenSlots,service.id)}
                                        </>
                                )}
                        </div>}
                    </div>
                    {
                        (service.jobType == "fixed" ) && (
                            <div className="service-details-row-wrapper-fixed advice">
                                <img src={require("../../assets/icons/Warning.svg")} alt="" />
                                <p className="service-details-p-advice">Para recibir el pago, es obigatorio completar todos los pasos, incluidas las fotos del trabajo terminado y la firma de conformidad.</p>
                            </div>
                        )
                    }                
                    <p className="service-details-description">Descripción detallada</p>
                    <p className="service-details-description-p">{service.description}</p>
                    <div className="service-details-wollyShop"  onClick={() => window.open('https://www.shop.wollyhome.com/', '_blank')}>
                        <span>¿Necesitas materiales para este servicio? <b>¡Ven a WollyShop!</b></span>
                        <img src={wrench} alt=""  />
                    </div>
                </div>
               
                <div className="service-details-images-wrapper">
                    {service.images ? service.images.map((image,imageIndex)=>{
                            if(imageIndex <6){
                                return (<div className="service-details-images" style={{background:"url("+image.url()+")"}}></div>)
                            }
                        })
                         :(<div className="service-details-images-no-pics" style={{backgroundImage:service.vertical?.get("DefaultPicture")?.url()? "url("+ service.vertical?.get("DefaultPicture")?.url()+")":"url("+require("../../assets/noPics.png")+")"}}></div>)
                    }
                    <div>
                        {
                                service.jobType == "fixed" && (
                                    <>
                                        <p className="service-details-description">El servicio incluye</p>    
                                        {
                                            service.includes && (
                                                <>
                                                    {service.includes.map( include => (
                                                        <p className="service-details-fixed-ganancy">{`- ${include}`}</p>
                                                    ))}
                                                </>
                                            )
                                        }
                                        <p className="service-details-description">El servicio NO incluye</p>    
                                        {
                                            service.doesntIncludes && (
                                                <>
                                                    {service.doesntIncludes.map( doesntInclude => (
                                                        <p className="service-details-fixed-ganancy">{`- ${doesntInclude}`}</p>
                                                    ))}
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                    </div>
                    
                </div>
            </div>
            <div className="service-details-wrapper-mobile">
                <div className="service-details-data-wrapper">
                    <div className="service-details-title-wrapper">
                        <img className="service-detail-vertical-img"  src={service.vertical ? service.vertical?.get("Icon")?.url() ? service.vertical?.get("Icon")?.url() : "" : service.verticalLogo._url}/>
                        <div>
                            <p className={service?.level  === 4 ? "Platino" : service?.level === 3 ? "Oro" : service?.level === 2 ? "Plata" : "Bronce"}>{service?.level  === 4 ? "Platino" : service?.level === 3 ? "Oro" : service?.level === 2 ? "Plata" : "Bronce"} - {service.type}</p>
                            <p className="service-details-title">{service?.title}</p>
                        </div>
                        {
                            service.business?.get("Logo")?.url() && (
                                <img  src={service.business?.get("Logo")?.url() ? service.business?.get("Logo")?.url(): ""}/>
                            )
                        }
                    </div>
                    <div className="service-details-info-wrapper">
                        <div className="service-details-container-wrapper">
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/User.svg")} alt="" />
                                <p className="service-details-p">{service.createdBy ? service.createdBy?.get("Name") : service.clientName}</p>
                            </div>
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/MapPinLine.svg")} alt="" />
                                <p className="service-details-p">{service.address}</p>
                            </div>
                            {!forOwn && <div className="service-details-row-wrapper">
                                <img style={{height:20}} src={require("../../assets/icons/Phone.svg")} alt="" />
                                <p className="service-details-p">   {service.createdBy ? service.createdBy?.get("Phone") : service.clientPhone}</p>
                            </div>}
                          
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/Clock.svg")} alt="" />
                                <p className="service-details-p">{service.appointment ? `Cita con el cliente: ${service.appointment.toString().substring(7,10)} ${service.appointment.toString().substring(3  ,8)} ${service.appointment.toString().substring(11,15)}` : `Subido ${moment(service?.createdAt).fromNow()}`}</p>
                            </div>
                            
                            {
                                service.fixedPrice && (
                                    <div className="service-details-row-wrapper mt-10">
                                        <p className="service-details-fixed-ganancy">Ganancia Directa:</p>
                                        <p className="service-details-fixed-price">{`${formatCurrency(service.fixedPrice)}  + IVA`}</p>
                                    </div>
                                )
                            }
                            {
                                service.jobState && service.jobState != "finalizado" && (
                                    <div className="service-details-row-wrapper mt-10">
                                        <img style={{width:20}} src={require("../../assets/icons/Warning.svg")} alt="" />
                                        <p className="service-details-p-advice">Para recibir el pago, es obigatorio completar todos los pasos, incluidas las fotos del trabajo terminado y la firma de conformidad.</p>
                                    </div>
                                )
                            }
                            {
                                service.jobState && service.jobState == "finalizado" && (
                                    <div className="service-details-row-wrapper mt-10">
                                        <p className="service-details-p-complete">SERVICIO COMPLETADO</p>
                                        <img style={{width:40, height: 40, marginLeft: 15}} src={require("../../assets/notifications/verified.png")} alt="" />
                                    </div>
                                )
                            }
                        </div>
                        
                    </div>
                    <div className="service-details-pics-wrapper">
                        <div className="service-details-images-wrapper">
                            {service.images ? service.images.map((image,imageIndex)=>{
                                    if(imageIndex <6){
                                        return (<div className="service-details-images" style={{background:"url("+image.url()+")"}}></div>)
                                    }
                                })
                                :(<div className="service-details-images-no-pics" style={{backgroundImage:service.vertical?.get("DefaultPicture")?.url()? "url("+ service.vertical?.get("DefaultPicture")?.url()+")":"url("+require("../../assets/noPics.png")+")"}}></div>)
                            }
                        </div>
                        <p className="service-details-description">Descripción detallada</p>
                        <p className="service-details-description-p">{service.description}</p>
                        {
                            service.jobType != "fixed" && (
                                <p className="service-details-description">Competencia</p>    
                            )
                        }
                        {
                            service.jobType != "fixed" && (
                                <div className="slotsContainer">
                                    {service?.totalSlots == 1 ? 
                                        (
                                            <>
                                                <img className="oneSlot" src={require("../../assets/n1.png")} alt="" />
                                                <p>SERVICIO SIN COPMPETENCIA</p>
                                            </>
                                        ) :(
                                            <>
                                                {calculateSlots(service?.totalSlots,service?.takenSlots,service.id)}
                                            </>
                                    )}
                                </div> 
                            )
                        }
                        {
                            service.jobType == "fixed" && (
                                <>
                                    <p className="service-details-description">El servicio incluye</p>    
                                    {
                                        service.includes && (
                                            <>
                                                {service.includes.map( include => (
                                                    <p className="service-details-fixed-ganancy">{`- ${include}`}</p>
                                                ))}
                                            </>
                                        )
                                    }
                                    <p className="service-details-description">El servicio NO incluye</p>    
                                    {
                                        service.doesntIncludes && (
                                            <>
                                                {service.doesntIncludes.map( doesntInclude => (
                                                    <p className="service-details-fixed-ganancy">{`- ${doesntInclude}`}</p>
                                                ))}
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        <div className="service-details-wollyShop"  onClick={() => window.open('https://www.shop.wollyhome.com/', '_blank')}>
                            <span>¿Necesitas materiales para este servicio? <b>¡Ven a WollyShop!</b></span>
                        </div>
                        {!forOwn && service.jobType == "fixed" ?(<div className="service-details-buy-button-wrapper">
                                <>
                                    <button onClick={()=>fixedProcess(service)} className="service-details-buy-button">INICIAR GESTIÓN</button>
                                    <button onClick={()=>generateLink(service)} className="service-details-buy-button-clear">Compartir URL</button>
                                </>
                        </div>):
                        (<div className="service-details-buy-button-wrapper">
                            {forOwn ? (  <button onClick={()=>handleBuy(service)} className="service-details-buy-button">CONTACTAR POR {formatCurrency(service.jobLeadPrice) }</button>):
                                        service.jobType == "fixed" ? <button onClick={()=>fixedProcess(service)} className="service-details-buy-button"> INICIAR GESTIÓN </button> :
                                (  <a href={`tel:${service.createdBy?.get("Phone")}`} className="service-details-buy-button">CONTACTAR</a>)
                            }
                        </div>)}
                        
                    </div>
                </div>               
            </div>
        </MainModal>
    )
}

export default ServiceDetails;