import React, {useState} from "react";
import { useStateValue } from "../../../state/context";

import ButtonEmpty from '../../button/ButtonEmpty'
import InfoCell from '../../search/detail/InfoCell'
import ContactModal from '../../modal/ContactModal'
import FiscalModal from '../../modal/FiscalModal'

import '../../../styles/search/detail/job-detail-container.css'

export default function Details() {

    const [{ businessDetails, user }] = useStateValue();
    const [showEditContact, setShowEditContact] = useState(false);
    const [showBillingModal, setShowBillingModal] = useState(false);

    const style={
        logo:{
            height: 48,
            width: 48,
            backgroundColor: "var(--soft-grey)",
            borderRadius: 24,
            overflow: 'hidden',
            objectFit: 'cover',
            marginRight: 12
        }
    }

    return (
        <div className="jobDetailColumn">
            {showEditContact && 
                <ContactModal
                    isVisible={showEditContact}
                    business={businessDetails.business}
                    onClose={() => setShowEditContact(false)}
                />
            }
            {showBillingModal &&
                <FiscalModal
                    isVisible={showBillingModal}
                    onClose={() => setShowBillingModal(false)}
                    business={businessDetails.business}
                    billingInfo={businessDetails.billing}
                    success={() => setShowBillingModal(false)}
                />
            }
            <section className="jobDetailContainer">
                <div style={{display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', alignItems: 'center'}}>
                    <img style={style.logo} src={businessDetails.business.get("Logo").url()} alt="business-logo" />
                    <label className="title">{businessDetails.business.get("Name")}</label>
                </div>
                <div className="detailContainer">
                    <InfoCell
                        title="ID del negocio"
                        data={businessDetails.business.id}
                        halfLeft={true}
                    />
                    <InfoCell
                        title="Nivel"
                        data={businessDetails.business.get("Level") === 4 ? "Platino" : businessDetails.business.get("Level") === 3 ? "Oro" : businessDetails.business.get("Level") === 2 ? "Plata" : "Bronce"}
                        logo={businessDetails.business.get("Level") === 4 ? require("../../../assets/badge-platinum.svg") : businessDetails.business.get("Level") === 3 ? require("../../../assets/badge-gold.svg") : businessDetails.business.get("Level") === 2 ? require("../../../assets/badge-silver.svg") : require("../../../assets/badge-bronce.svg")}
                        halfRight
                    />
                </div>
                <InfoCell
                    title="Dirección"
                    data={businessDetails.business.get("Address")}
                />
                <div style={{display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', marginTop: 24}}>
                    <label className="subTitle">{"Datos de facturación"}</label>
                    {user.permissions.business.edit &&
                        <ButtonEmpty
                            style={{display: 'flex', flex: 1, justifyContent: 'flex-end', color: '#3543FF'}}
                            buttonText={"Editar"}
                            onClick={() => setShowBillingModal(true)}
                        />
                    }  
                </div>
                <div className="detailContainer">
                    <InfoCell
                        title="Nombre completo"
                        data={businessDetails.billing ? businessDetails.billing.get("FiscalName") : "-"}
                        halfLeft={true}
                    />
                    <InfoCell
                        title="CIF o NIF"
                        data={businessDetails.billing ? businessDetails.billing.get("IdentityNumber") : "-"}
                        halfRight
                    />
                </div>
                <InfoCell
                    title="Dirección de facturación"
                    data={businessDetails.billing ? businessDetails.billing.get("FiscalAddress") : "-"}
                />
                <div style={{display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', marginTop: 24}}>
                    <label className="subTitle">{"Contacto"}</label>
                    {user.permissions.business.edit &&
                        <ButtonEmpty
                            style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}
                            buttonText={"Editar"}
                            onClick={() => setShowEditContact(true)}
                        />
                    }
                </div>
                <div className="detailContainer">
                    <InfoCell
                        title="Nombre"
                        data={businessDetails.business.get("ContactName") ? businessDetails.business.get("ContactName") : "-"}
                        halfLeft={true}
                    />
                    <InfoCell
                        title="Teléfono"
                        data={businessDetails.business.get("ContactPhone") ? businessDetails.business.get("ContactPhone") : "-"}
                        halfRight
                    />
                </div>
                <InfoCell
                    title="Email"
                    data={businessDetails.business.get("ContactEmail") ? businessDetails.business.get("ContactEmail") : "-"}
                />
            </section>
        </div>
    )
}