import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../state/context";
import { initialState } from "../state/initialState";
import { deleteCookie } from "../utils/cookie";
import { hasClassInTree } from "../utils/checkAncestorDOM";
import { formatCurrency } from "../utils/formatter";

import logo_negro from "../assets/icons/isotipo-negro.png"
import logo from "../assets/icons/isotipo-amarillo.png"
import icon_task from "../assets/icons/icon-tasks.png"
import icon_map from "../assets/icons/icon-map.png";
import icon_jobs from "../assets/jobs-icon.png";

import icon_user from "../assets/icons/icon-user.png"
import icon_user_mobile from "../assets/icons/User.svg"
import icon_billing_mobile from "../assets/icons/CurrencyCircleDollar.svg"
import icon_owned_mobile from "../assets/icons/ShoppingCart.svg"
import icon_maps_mobile from "../assets/icons/GlobeHemisphereEast.svg"
import icon_explore_mobile from "../assets/icons/Crosshair.svg"
import icon_logout_mobile from "../assets/icons/SignOut.svg"
import icon_bussines from "../assets/icons/icon-bussines.png"
import icon_billing from "../assets/icons/icon-billing.png"
import icon_kpis from "../assets/icons/icon-kpis.png"
import icon_agentAdmin from "../assets/agents-icon.png"
import icon_notification from "../assets/icons/icon-notification.png"
import icon_stadistics from "../assets/campains/ChartLine.svg"
import icon_settings from "../assets/icons/icon-settings.png"
import { MenuList } from "jobin-ui-components";
import RechargeMoneyModal  from "../components/modal/RechargeMoneyModal.jsx";

import "../styles/side-menu.css";

export default function SideMenu(props) {
    const [{ user, crmNotifications ,agent,currentSession,general,explore}, dispatch] = useStateValue();
    const [showSettings, setShowSettings] = useState(false);
    const [showMoneyModal, setShowMoneyModal] = useState(false)
    const [showAdmin, setShowAdmin] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        dispatch({
            type: "SHOW_MONEY_MODAL",
            showMoneyModal: setShowMoneyModal
        });
    
      
    }, [])

    const home = (
        // <svg viewBox="0 0 36 40">
        //     <path
        //         fill="#fff"
        //         fillRule="evenodd"
        //         d="M19.188 3.216c.679 0 1.23.549 1.23 1.226 0 .677-.551 1.225-1.23 1.225a1.227 1.227 0 1 1 0-2.451zm3.81-.356s-2.133-1.765-2.74-1.91C18.916.625 17.628.48 15.97.48A22.011 22.011 0 0 0 .481 6.822a13.634 13.634 0 0 1 7.29.942 5.233 5.233 0 0 0-2.285 2.545c5.436-.25 8.945 1.234 10.267 4.787 0 0 4.298-.608 5.9 1.783-5.506-1.809-10.101 2.343-10.101 6.855 0 6.441 7.636 15.378 7.636 15.378s7.996-8.824 7.996-17.723c0-4.398-3.25-8.248-6.24-10.219l11.69-6.754c.017-.01.035-.017.05-.026.017-.009.033-.022.05-.032A5.172 5.172 0 0 0 35.218.711L22.997 2.86z"
        //     />
        // </svg>
        <img id="wolly-logo" alt="wolly-logo" src={logo}></img>
    );
 
    const tasks = (
        <img alt="task" style={{width:"35px", height:"35px"}} src={icon_jobs}></img>
    );

    const map = (
        <img alt="map" style={{width:"35px", height:"35px"}} src={icon_task}></img>
    )

    const userPic = (
        <img alt="user" style={{width:"35px", height:"35px"}} src={icon_user}></img>
       
    );

    const business = (
        <img alt="bussines" style={{width:"35px", height:"35px"}} src={icon_bussines}></img>
       
    );

    const billing = (
        <img alt="billing" style={{width:"35px", height:"35px"}} src={icon_billing}></img>
        
    );

    const purchasedJobs = (
        <img alt="purchasedJobs" style={{width:"35px", height:"35px"}} src={icon_bussines}></img>
        
    );
    const kpis = (
        <img alt="kpis" style={{width:"30px", height:"30px"}} src={icon_kpis}></img>
        
    );
    const agentAdmin = (
        <img alt="agentAdmin" style={{width:"22px", height:"22px"}} src={icon_agentAdmin}></img>
        
    );

    const editVerticals = (
        
        <img alt="settings" style={{width:"25px", height:"25px"}} src={icon_settings}></img>
        );
    const settings = (
        
        <img alt="settings" style={{width:"25px", height:"25px"}} src={icon_settings}></img>
        );
        
    const match = (
        
        <img alt="notif" style={{width:"25px", height:"25px"}} src={icon_notification}></img>
    );

    const stadistic = (
        
        <img alt="notif" style={{width:"25px", height:"25px"}} src={icon_stadistics}></img>
    );

    const notificationsBadge = (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <defs>
                <path
                    d="M3.3691,19.3862 L4.8621,14.3672 C5.0381,13.7752 5.0741,13.1412 4.9681,12.5322 L4.5281,10.0432 C3.9531,6.7802 6.1321,3.6682 9.3951,3.0922 C12.6581,2.5172 15.7701,4.6962 16.3461,7.9592 L16.7851,10.4482 C16.8921,11.0572 17.1431,11.6412 17.5121,12.1372 L20.6311,16.3432 L3.3691,19.3862 Z M10.0001,22.0002 C8.9711,22.0002 8.0611,21.4782 7.5201,20.6852 L12.8971,19.7372 C12.5661,21.0342 11.3981,22.0002 10.0001,22.0002 L10.0001,22.0002 Z M22.2371,15.1512 L19.1181,10.9462 C18.9321,10.6962 18.8081,10.4072 18.7551,10.1012 L18.3151,7.6122 C17.5491,3.2612 13.3991,0.3552 9.0481,1.1232 C4.6971,1.8902 1.7921,6.0392 2.5581,10.3902 L2.9981,12.8802 C3.0521,13.1852 3.0331,13.5002 2.9451,13.7972 L1.4521,18.8162 C1.0281,20.2412 2.2521,21.6142 3.7171,21.3562 L5.4551,21.0492 C6.2391,22.7842 7.9721,24.0002 10.0001,24.0002 C12.6341,24.0002 14.7681,21.9572 14.9631,19.3732 L20.9781,18.3122 C22.4421,18.0542 23.1231,16.3452 22.2371,15.1512 L22.2371,15.1512 Z"
                    id="path-badge"
                ></path>
            </defs>
            <g
                id="Icono-/-Notificaciones"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <circle
                    id="Oval"
                    fill="#fff"
                    cx="18.5"
                    cy="5.5"
                    r="5.5"
                ></circle>
                <mask id="mask-badge" fill="white">
                    <use xlinkHref="#path-badge"></use>
                </mask>
                <g id="Mask"></g>
                <g
                    id="✱-/-Color-/-Black-/-800"
                    mask="url(#mask-badge)"
                    fill="#fff"
                >
                    <rect
                        id="Rectangle-13"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                    ></rect>
                </g>
            </g>
        </svg>
    );

   

    const reset = () => {
        dispatch({
            type: "JOB_RESET",
            job: initialState.job
        });
        dispatch({
            type: "JOB_DETAILS_RESET",
            fixedInfo: initialState.jobDetails.fixedInfo,
            transportInfo: initialState.jobDetails.transportInfo,
            paymentsArray: initialState.jobDetails.paymentsArray,
            historyArray: initialState.jobDetails.historyArray,
            extrasArray: initialState.jobDetails.extrasArray,
            jobersArray: initialState.jobDetails.jobersArray
        });
    };

    const menuListOptionClick = item => {
        setShowSettings(false);
        if (item.id === "logout") {
            reset();
            deleteCookie("agent");
            dispatch({
                type: "SET_USER_PERMISSIONS",
                permissions: undefined
            });
            props.history.replace({
                pathname: "/login",
                search: props.location.search,
                state: {
                    inner: true
                }
            });
        }else if(item.id=="password"){
            props.showPassword()
        }else if(item.id == "agents"){
            props.history.replace({
                pathname: "/agents",
                search: props.location.search,
                state: {
                    inner: true
                }
            });
        }else if(item.id == "verticals"){
            props.history.replace({
                pathname: "/verticals",
                search: props.location.search,
                state: {
                    inner: true
                }
            });
        }
    };

    return (
        <>
            <div id="side-menu">
                
                <ul id="top-ul">
                    <li id="home-logo" >
                        {home}
                    </li>
                    {!user.isGoldWoller && user?.permissions && !user.permissions.search_garages && (
                            <li>
                                <Link
                                    id="side-search"
                                    to={`${props.match.path}job`}
                                    title="Trabajos"
                                    className={
                                        props.location.pathname.includes("job")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {tasks}
                                </Link>
                            </li>
                        )
                    }
                     {user.isGoldWoller && user.permissions && user.permissions.map && !user.isMediator &&
                            <li>
                                <Link
                                    id="side-explore"
                                    to={`${props.match.path}explore`}
                                    title="Explorar"
                                    className={
                                        props.location.pathname.includes("explore")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {tasks}
                                </Link>
                            </li>
                    }
                    {
                        user.permissions && user.permissions.map &&
                            <li>
                                <Link
                                    id="side-maps"
                                    to={`${props.match.path}maps`}
                                    title="Maps"
                                    className={
                                        props.location.pathname.includes("maps")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {map}
                                </Link>
                            </li>
                    }
                    
                    {user?.isGoldWoller && ( <li>
                                <Link
                                    id="side-search"
                                    to={`${props.match.path}purchased-jobs`}
                                    title="Trabajos Comprados"
                                    className={
                                        props.location.pathname.includes("job")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {purchasedJobs}
                                </Link>
                            </li>)}
                            
                    {user?.isGoldWoller && ( <li>
                        <a
                            id="side-search"
                            title="Usuarios"
                            className={
                                props.location.pathname.includes("billing")
                                    ? "active"
                                    : ""
                            }
                            onClick={() => window.open('https://www.shop.wollyhome.com/', '_blank')}
                        >
                            {billing}
                        </a>
                    </li>)}
                    {user.permissions &&
                        user.permissions.user &&
                        user.permissions.user.see && !user.isMediator && ( <li>
                        <Link
                            id="side-search"
                            to={`${props.match.path}user${user.goldId?"/"+user.goldId:""}`}
                            title="Usuarios"
                            className={
                                props.location.pathname.includes("user")
                                    ? "active"
                                    : ""
                            }
                            onClick={reset}
                        >
                            {userPic}
                        </Link>
                    </li>)}

                    {!user?.isGoldWoller && user.permissions &&
                        user.permissions.business &&
                        user.permissions.business.see && !user.isMediator && (
                            <li>
                                <Link
                                    id="side-business"
                                    to={`${props.match.path}business`}
                                    title="Business"
                                    className={
                                        props.location.pathname.includes("business")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {business}
                                </Link>
                            </li>
                        )}
                        
                    { !user?.isGoldWoller && user.permissions &&
                        user.permissions.billing &&
                        user.permissions.billing.see && !user.isMediator && (
                            <li>
                                <Link
                                    id="side-billing"
                                    to={`${props.match.path}billing`}
                                    title="Billing"
                                    className={
                                        props.location.pathname.includes("billing")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {billing}
                                </Link>
                            </li>
                        )}
                    { !user.isMediator &&
                    !user.isGoldWoller &&
                    agent &&
                    (agent.dept == "CEO" ||
                    agent.dept == "Tech" ||
                    agent.dept == "QA" ||
                    agent.dept == "ChiefOps" ||
                    user.isB2b)  && (
                            <li>
                                <Link
                                    id="side-kpis"
                                    to={`${props.match.path}kpis`}
                                    title="Kpis"
                                    className={
                                        props.location.pathname.includes("kpis")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {kpis}
                                </Link>
                            </li>
                        )}
                        
                    { !user.isMediator &&
                    !user.isGoldWoller &&
                    agent &&
                    (agent.dept == "CEO" ||
                    agent.dept == "Tech" ||
                    agent.dept == "QA" ||
                    agent.dept == "ChiefOps" )  && (
                            <li>
                                <Link
                                    id="side-match"
                                    to={`${props.match.path}match`}
                                    title="Match"
                                    className={
                                        props.location.pathname.includes("match")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {match}
                                </Link>
                            </li>
                        )}
                    { !user.isMediator &&
                    !user.isGoldWoller &&
                    agent &&
                    (agent.dept == "CEO" ||
                    agent.dept == "Tech")  && (
                            <li>
                                <Link
                                    id="side-campains"
                                    to={`${props.match.path}campains`}
                                    title="Estadísticas"
                                    className={
                                        props.location.pathname.includes("campains")
                                            ? "active"
                                            : ""
                                    }
                                    onClick={reset}
                                >
                                    {stadistic}
                                </Link>
                            </li>
                        )}
                    </ul>
                <ul id="bottom-ul">
                { agent && (agent.dept == "CEO" || agent.dept == "Tech") && user.permissions?.crm && (
                    <Link
                        id="side-agents"
                        to="#"
                        title="Admin"
                        className={props.location.pathname.includes("agents") || props.location.pathname.includes("verticals")
                                        ? "active"
                                    : ""}
                        onClick={() => setShowAdmin(!showAdmin)}
                    >
                        {showAdmin && (
                            <MenuList
                                style={{ zIndex: 1000,top:"-70px",left:"45px" }}
                                items={[{ id: "agents", title: "Agentes" },{ id: "verticals", title: "Verticales" }]}
                                buttonItemSelected={menuListOptionClick}
                            />
                        )}
                        {agentAdmin}
                    </Link>)
                }
                
                {/* {!user?.isGoldWoller && user.permissions &&
                        user.permissions.notifications &&
                        user.permissions.notifications.see && !user.isMediator && ( 
                    <Link
                        style={{ cursor: "pointer" }}
                        id="side-notification"
                        to="#"
                        title="Notification"
                        className={crmNotifications.showMenu ? "active" : ""}
                        onClick={() => {
                            dispatch({
                                type: "NOTIFICATION_MENU",
                                showMenu: true
                            });
                        }}
                    >
                        {crmNotifications.notifications.length > 0
                            ? notificationsBadge
                            : notifications}
                        </Link>)} */}
                    <Link
                        id="side-settings"
                        to="#"
                        title="Settings"
                        className={showSettings ? "active" : ""}
                        onClick={() => setShowSettings(!showSettings)}
                    >
                        {showSettings && (
                            <MenuList
                                style={{ zIndex: 1000,top:"-70px",left:"45px" }}
                                items={[{ id: "logout", title: "Cerrar sesión" },{ id: "password", title: "Cambiar contraseña" }]}
                                buttonItemSelected={menuListOptionClick}
                            />
                        )}
                        {settings}
                    </Link>
                </ul>
            </div>
           <div id="menuToggle">
                <div id="hambContainer">
                    <input checked={showMobileMenu} onChange={()=>setShowMobileMenu(!showMobileMenu)} type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        {!user.isB2b && <Link id="side-services" to={`${props.match.path}explore`} title="Maps" className="link" onClick={()=>setShowMobileMenu(false)}>
                            <img src={icon_explore_mobile}/><li>Explorar</li>
                        </Link>}
                        {!user.isB2b && <Link id="side-maps" to={`${props.match.path}maps`} title="Maps" className="link" onClick={()=>setShowMobileMenu(false)}>
                            <img src={icon_maps_mobile}/><li>Mapa</li>
                        </Link>}
                       {!user.isB2b &&  <Link id="side-ownedJobs" to={`${props.match.path}purchased-jobs`} title="Maps" className="link" onClick={()=>setShowMobileMenu(false)}>
                            <img src={icon_owned_mobile}/><li>Mis servicios</li>
                        </Link>}
                        {!user.isB2b && <Link id="side-user"  to={`${props.match.path}user${user.goldId?"/"+user.goldId:""}`} title="Maps" className="link" onClick={()=>setShowMobileMenu(false)}>
                            <img src={icon_billing_mobile}/><li>Wolly Shop</li>
                        </Link>}     
                        {!user.isB2b && <Link id="side-user"  to={`${props.match.path}user${user.goldId?"/"+user.goldId:""}`} title="Maps" className="link" onClick={()=>setShowMobileMenu(false)}>
                            <img src={icon_user_mobile}/><li>Mi perfil</li>
                        </Link>}     
                        <Link id="logout"  title="Maps" className="link" onClick={()=>menuListOptionClick({id:"logout"})}>
                            <img src={icon_logout_mobile}/><li>Cerrar Sesion</li>
                        </Link>
                    </ul>
                </div>
                <div className="dataContainer">
                    {showMoneyModal && <RechargeMoneyModal
                        isVisible={showMoneyModal}
                        data={props.job}
                        onClose={()=>setShowMoneyModal(false)}
                        refreshJobs ={props.refresh}
                        packs={general.packs}
                    />}
                   {(props.location.pathname.includes("explore"))  &&
                    <>
                        <p className="njobs">{explore?.count} trabajos</p>
                        <p className="customZone">Tu zona personalizada</p>
                    </>
                    }
                </div>
                <button onClick={()=>setShowMoneyModal(true)} className={user.isUserSubscription ? "balanceButton infinite":"balanceButton"}> {user.isUserSubscription ? "∞":formatCurrency(currentSession.wollerData?.get("nJobs"))}</button>
               <div className={showMobileMenu ?"menuShadow active": "menuShadow"}></div>

            </div>
        </>
    );
}
