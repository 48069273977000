import React, { useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../state/context";
import { levelArr } from '../../constants/levels'

import {SearchBar} from 'jobin-ui-components'
import FilterMultipleSelection from "../filter/FilterMultipleSelection";

import "../../styles/search/search-nav.css";

export default function SearchNav() {

    const [{ businessSearch }, dispatch] = useStateValue();

    const styles = {
        above:{
            borderBottom: '1px solid var(--soft-grey)',
            paddingTop: 16,
            paddingBottom: 8,
        }
    }

    useEffect(() => {
       getBusiness();
    }, []);

    const getBusiness = async (levelArr, id) =>{
        dispatch({
            type: "BUSINESS_SEARCH_LOADING",
            loading: true
        });

        const result = await Jobin.Business.getMainBusinesses(levelArr, id);
        if(result.status === 'ok'){
            dispatch({
                type: "BUSINESS_SEARCH",
                items: result.result
            });
        }
        dispatch({
            type: "BUSINESS_SEARCH_LOADING",
            loading: false
        });
    }

    const fetchBusinessByLevel = async selection =>{
        const tempArray = selection.map((item) =>{
            return Number(item.id);
        });
        dispatch({
            type: "BUSINESS_SEARCH_FILTER",
            levels: tempArray
        });
        getBusiness(tempArray, businessSearch.id);
    }

    const fetchBusinessByName = async input =>{
        getBusiness(businessSearch.levels, input);
    }

    const inputOnKeyPress = e => {
        const keyCode = e.keyCode || e.which;
        const query = e.target.value;
        if (keyCode === 13){
            dispatch({
                type: "BUSINESS_SEARCH_FILTER",
                id: query
            });
            fetchBusinessByName(query);
        }
    };

    return (
        <section className="search-nav">
            <div style={styles.above}>
                <div className="container-fluid">
                    <div className="col-md-6">
                        <div className="row">
                            <SearchBar
                                placeholder="Buscar por nombre"
                                onKeyPress={inputOnKeyPress}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="below">
                <div className="below-left">
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="level"
                        title="Nivel"
                        items={levelArr}
                        selectionChange={fetchBusinessByLevel}
                    />
                </div>
            </div>
        </section>
    );
}
