
//ENV
  export const urlServer = process.env.REACT_APP_URL_SERVER;
  export const wannme_pay_url = process.env.REACT_APP_WANNME_PAY_URL
  export const apiId = process.env.REACT_APP_API_ID;
  export const jsKey = process.env.REACT_APP_JS_KEY;
  export const apiServer = process.env.REACT_APP_API_SERVER;
  
//ALL
  export const apiKey = "eVxqt79FQMFtAgMN8XfZ9O1yiWg0AY7b";
  export const stripeKey = "pk_live_hUp7w6YcV02GJZU4A0mnfyoz";
  export const partnerId = "q-slqjrenswv3jsf4h97";
  export const privateKey = "CElF-ixsXXLOg28IiYx*rgaByCCTBV";
  export const wannmeApiKey = "eyJhbGciOiJIUzUxMiJ9.eyJ3dMOaIjoidjDDsWJtY24yMzAiLCJ5aGVyw40iOiJGNkFPWFBKQUZHNiIsIsONbGp3w5NlbHjDjWzDmiI6IsOaw5Nxw40iLCJyczlldXdvZUhxbMOaZTjDjWzDmnQiOiIzYmIiLCJoZXXDjSI6ImR3RDlWNkF3SWtod01LWGJCaWkwK3c9PSIsInlxayI6IjNiYiIsInd1IjoiYjFjIiwic8ONIDZ0eMONIjoiw4l0bGx4w43CpHRyd8Kkw5rDjXnDmiJ9.Jx6TeriA1M7_Asqf_uQ8AbwguYDWbeEeC-OBoHIQKMXiuYY1eVr0aD9UqVsIebioSc1H8gL8kjDd6rdOBPHVRA"

/**/


// here i need to check if the crmuser has a business pointer, if it has, i should only create jobs associated with that business, with no rights of changing it
// and also, i can only see and filter through this jobs that has this business associated DONE ONLY ON JOB VIEW 