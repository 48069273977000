import React, { useEffect,useState } from "react";
import { useStateValue } from "../../state/context";
import { provinceArr } from "../../constants/provinces";
import { levelArr } from "../../constants/levels";
import MultiSelect from "../singleComponents/MultiSelect.jsx";
import RangeDatePicker from "../singleComponents/RangeDatePicker";
import { useDebounce } from "../../utils/hooks";



import GoogleMapReact from 'google-map-react';

import "../../styles/explore/mapCard.css";
export default function MapCard({findJobsforBiPro}) {
    const [{general,currentSession},dispatch] = useStateValue();
    const [date_, setDate_] = useState()
    const [provinces_, setProvinces] = useState(undefined)
    const [guilds_, setGuilds] = useState(undefined)
    const [levels_, setLevels] = useState("")
    const [slots_, setSlots] = useState(undefined)


    const debounceDate = useDebounce(date_, 500);
    const debounceProvinces = useDebounce(provinces_, 1000);
    const debounceGuilds = useDebounce(guilds_, 1000);
    const debounceLevels = useDebounce(levels_, 1000);
    const debounceSlots = useDebounce(slots_, 1000);


    const center = {
        lat: currentSession.wollerData.get("Location").latitude,
        lng: currentSession.wollerData.get("Location").longitude
    };
    const zoom = 11.8;
    const jobSlots = [
        {
            id: "all",
            name: "Todos",
            selected: false
        },
        {
            id: "1",
            name: "sin competencia",
            selected: false
        },
        {
            id: "3",
            name: "hasta 3 presupuestos",
            selected: false
        },
    ]

    useEffect(()=>{
        if(debounceDate!== undefined && debounceDate.startDate!== null ){
            dispatch({
            type: "EXPLORE_FILTER_DATE",
                startDate: debounceDate.startDate ? new Date(debounceDate.startDate):debounceDate.startDate,
                endDate: debounceDate.endDate ? new Date(debounceDate.endDate):debounceDate.endDate,
            });
         
            findJobsforBiPro({startDate:debounceDate.startDate,endDate:debounceDate.endDate});
        }
    },[debounceDate])
    useEffect(()=>{
        console.log("debounceProvin",debounceProvinces)
        if(debounceProvinces !== undefined){
            dispatch({
               type: "EXPLORE_FILTER_PROVINCES",
               provinces:debounceProvinces,
           });
           
            findJobsforBiPro({provinces:debounceProvinces});
        }
    },[debounceProvinces])
    useEffect(()=>{
        console.log("debounceGuilds",debounceGuilds)
        if(debounceGuilds !== undefined){
            dispatch({
                type: "EXPLORE_FILTER_GUILDS",
                guilds:debounceGuilds,
            });
            
            findJobsforBiPro({guilds:debounceGuilds});
        }
    },[debounceGuilds])
    useEffect(()=>{
        console.log(debounceLevels)
        if(debounceLevels !== ""){
            dispatch({
                type: "EXPLORE_FILTER_LEVELS",
                levels:debounceLevels,
            });
            
            findJobsforBiPro({levels:debounceLevels});
        }
    },[debounceLevels])
    useEffect(()=>{
        console.log("debounceSlots ",debounceSlots )
        if(debounceSlots !== undefined){
            dispatch({
                type: "EXPLORE_FILTER_SLOTS",
                slots:debounceSlots,
            });
            
            findJobsforBiPro({slots:debounceSlots});
        }
    },[debounceSlots])

    const showDateSelected=(date)=>{
        setDate_({startDate:date.startDate,endDate:date.endDate})
    }
    const showSelectedGuild=(data)=>{
         data = data.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
       
        setGuilds(data)
    }
    const showSelectedProvinces=(data)=>{
        data = data.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
        
        setProvinces(data)
    }
    const showSelectedSlots=(data)=>{
         data = data.map(selected=>{
            if(selected.id !== "none"){
                return parseInt(selected.id)
            }
        }).filter(x=>x)
       
        setSlots(data)
    }
    const handleLevelSelected=(level)=>{
        level = parseInt(level)
        if(levels_ && levels_.some(level_=>level_ == level)){
            let parseLevels = levels_.filter(level_=> level_!= level)
            setLevels(parseLevels)
        }else if(levels_){
            setLevels([...levels_,level])
        }else{
            setLevels([level])
        }
    }
    

    return (
        <div className="mapCard card">
            <div className="mapContainer">

                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus" }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    
                >
                </GoogleMapReact>

            </div>
            
            <RangeDatePicker
                title = "Fecha de creacion"
                type="date"
                startDate={undefined}
                endDate={undefined}
                handleDateSelected={showDateSelected}
                appointmentFilter={true}
            />
            <MultiSelect title="Gremios" values={general?.guilds}  handleSelected={showSelectedGuild}/>
            <MultiSelect title="Zonas de trabajo" values={provinceArr} handleSelected={showSelectedProvinces}/>
            

            <div>
                <MultiSelect title="Tipo de servicio" values={jobSlots} handleSelected={showSelectedSlots}/>
                <div className="checkBoxes">
                    <p className="titleCheckbox">Nivel del servicio</p>
                    {levelArr.map((level)=>{
                        
                            return (
                                <label key={"level"+level.id} >
                                    {level.name}
                                    <input type="checkbox" name="" id="" checked={(levels_ && levels_.some(level_=>level_ == level.id))
                                        ||(levels_ && levels_.length>3)} onChange={()=>handleLevelSelected(level.id)} />
                                </label>
                            )
                            
                        })
                    } 
                </div>
            </div>
        </div>
    )
}