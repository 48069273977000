import React, { useState } from "react";
import {generatePaymentLink,createPaymentInProcess} from "../../utils/ParserUtilities";
import * as cardSpinnerData from "../../assets/animations/button-loading.json";
import { useStateValue } from "../../state/context";

import Lottie from 'react-lottie'


import MainModal from "./MainModal"

const RechargeMoneyModal = ({isVisible,onClose,packs}) => {
    const [{currentSession}] = useStateValue();

    const [selectedPack,setSelectedPack] = useState(false)
    const [loading, setLoading] = useState(false);
    const selectPack = (pak)=>{
        setSelectedPack(pak)
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const buyPack = async ()=>{
        setLoading(true)
        try{
            let paymentLink = await generatePaymentLink({priceId:selectedPack.priceId})
            await createPaymentInProcess({packId:selectedPack.id,from:"web",jober:currentSession.wollerData.id,paymentLink:paymentLink.id})
            window.open(paymentLink.url, "_blank")
        }catch(error){
            setLoading(false)
            console.log(error)
        }

    }
    return (
   
        <MainModal
            classNamesModal = {{modal:"moneyModal",closeButton:"closeButtonMoney"}}
			isVisible={isVisible}
			onClose={()=>onClose()}
            center={true}
            banner={require("../../assets/badgePlatinum.png")}
            title={"Activa tu plan"}
            subtitle={"Define tu presupuesto; nosotros nos encargamos de maximizar tus resultados"}
		>
                <p className="pricesTitle">Recargas más populares</p>
                <div className="packsContainer">
                    {packs && packs.length > 0 && packs.map((pak)=>{
                        return (<div onClick={()=>{selectPack(pak)}} className={pak.popular ? "pack popular":selectedPack.id == pak.id  && pak.popular?"pack popular selected": selectedPack.id == pak.id? "pack selected":"pack" }key={pak.id} >
                                    <img className="imgPack" src={pak.image}  alt="pak" />
                                    <div>
                                        <p className="pricePack">{pak.credits}€</p>
                                        <p className="ivaPack">IVA incluido</p>
                                        <p className="aprox">Aproximadamente</p>
                                        <p className="verifiedUsers">{Math.round(pak.credits/8)} contactos verificados</p>

                                    </div>
                                </div>)
                    })}
                    
                    
                </div>
                <div className="buttonContainer"> 
                    <img  src={require("../../assets/securePay.png")} alt="" srcset="" />
                    <p className="safeTextMobile">¡Recuerda que puedes <b>financiar</b> tu inversión con nosotros!</p>
                    <p className="footerDisclaimer">Paga sólo por contactos verificados de clientes reales. Nosotros nos encargamos de llevarte a la palma de tu mano los mejores clientes para que tú solo tengas que contactarles y presupuestarles</p>
                    <img className="safePayMobile" src={require("../../assets/securePay.png")} alt="" srcset="" />
                </div>
            
               {selectedPack &&  <button onClick={()=>buyPack()} className="rechargeMoneyButton" disabled={!selectedPack}>{selectedPack != false ? `Recargar ${selectedPack.credits} €`:!loading ?"Selecciona un pack":
                    <Lottie
                        style={{height: 30, width: 80}}
                        options={defaultOptions}
                    />}
                </button>}
        </MainModal>
    )
}

export default RechargeMoneyModal;