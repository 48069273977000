import React, { useEffect, useState, useRef } from "react";
import Jobin from "jobin-client";
import moment from "moment";
import { useStateValue } from "../../state/context";
import { levelArr } from "../../constants/levels";
import { provinceArr } from "../../constants/user/provinces";
import fetchServices from "../../constants/services";
import {searchWollers} from "../../utils/ParserUtilities";
import { apiId, jsKey, urlServer  } from '../../constants/credentials';
import Parse from "parse";

import { SearchBar, ButtonTextImage, Button } from "jobin-ui-components";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";
import FilterSingleSelection from "../filter/FilterSingleSelection";
import FilterDate from "../filter/FilterDate";
import FilterSlider from "../filter/FilterSlider";
import ButtonEmpty from "../../components/button/ButtonEmpty";
import ImportWollersModalp from '../../components/modal/ImpotWollersModal'
import "../../styles/search/search-nav.css";
import fetchBusinesses from "../../constants/businesses";

export default function SearchNav(props) {
    const [{user, userSearch,currentSession }, dispatch] = useStateValue();
    const [guildArr, setGuildArr] = useState([]);
    const [business, setBusiness] = useState([]);
    const [businessArr, setBusinessArr] = useState([]);

    const [showImportModal, setShowImportModal] = useState(false);

    const csvRef = useRef(null);
    const styles = {
        above: {
            borderBottom: "1px solid var(--soft-grey)",
            paddingTop: 16,
            paddingBottom: 8
        },
        select: {
            backgroundColor: "transparent",
            border: "none",
            zIndex: 1,
            left: "8px"
        }
    };
    
    useEffect(() => {
        async function fetchAsync() {
            setGuildArr(await fetchServices(userSearch.guilds));
            setBusinessArr(await fetchBusinesses(userSearch.business));
        }
        if (user.isGoldWoller){
            const selectedArr = props.typeArray.filter(
                item => item.id === userSearch.idType
            );
            dispatch({
                type: "USER_SEARCH_SELECTED",
                selected: selectedArr[0]
            });
            
        }else{
            const selectedArr = userSearch.typeArray.filter(
                item => item.id === userSearch.idType
            );
            dispatch({
                type: "USER_SEARCH_SELECTED",
                selected: selectedArr[0]
            });
        }

        fetchAsync();
        getUserOrJobers(
            userSearch.level,
            userSearch.provice,
            userSearch.points,
            userSearch.leads,
            null,
            null,
            null,
            user?.isGoldWoller ? currentSession?.wollerData?.get("Phone"):null,
            null,
            null
        );
        getAllBusiness();
    }, []);

    const getAllBusiness = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getAllBusiness", {});

        setBusiness(res);
    }
 
    const getUserOrJobers = async (
        level,
        location,
        points,
        leads,
        startDate,
        endDate,
        guilds,
        phone,
        message,
        business,
        selected_="jober"
    ) => {
        dispatch({
            type: "USER_SEARCH_LOADING",
            loading: true
        });
        let verticales =
            guilds && Array.isArray(guilds)
                ? guilds.map(item => item.name)
                : null;
 

        if (selected_ === "jober") {
            const result = await searchWollers(
                level,
                10,
                location,
                points,
                leads,
                startDate,
                endDate,
                verticales,
                phone,
                100,
                message,
                business
            );
            
         
            if (result.status === "ok") {
                dispatch({
                    type: "USER_SEARCH",
                    items: result.result
                });
            } else {
                alert(result.result);
            }
        } else if (selected_ === "user") {
            const result = await Jobin.Client.search(
                level,
                startDate,
                endDate,
                phone
            );
            if (result.status === "ok") {
                dispatch({
                    type: "USER_SEARCH",
                    items: result.result
                });
            } else {
                alert(result.result);
            }
        }
        dispatch({
            type: "USER_SEARCH_LOADING",
            loading: false
        });
    };

    const fetchByDate = async date => {
        const newStartDate = date.startDate ? new Date(date.startDate) : null;
        const newEndDate = date.endDate ? new Date(date.endDate) : null;

        dispatch({
            type: "USER_SEARCH_FILTER",
            startDate: newStartDate,
            endDate: newEndDate
        });
        getUserOrJobers(
            userSearch.level,
            userSearch.location,
            userSearch.points,
            userSearch.leads,
            newStartDate,
            newEndDate,
            userSearch.guilds,
            null,
            null,
            userSearch.business
        );
    };

    const fetchByLevel = async selection => {
        const tempArray = selection.map(item => {
            return Number(item.id);
        });

        dispatch({
            type: "USER_SEARCH_FILTER",
            levels: tempArray.length > 0 ? tempArray : null
        });
        getUserOrJobers(
            tempArray.length > 0 ? tempArray : null,
            userSearch.location,
            userSearch.points,
            userSearch.leads,
            userSearch.startDate,
            userSearch.endDate,
            userSearch.guilds,
            null,
            null,
            userSearch.business
        );
    };

    const fetchByPoints = async points => {
        dispatch({
            type: "USER_SEARCH_FILTER",
            points: Number(points)
        });
        getUserOrJobers(
            userSearch.levels,
            userSearch.location,
            Number(points),
            userSearch.leads,
            userSearch.startDate,
            userSearch.endDate,
            userSearch.guilds,
            null,
            null,
            userSearch.business
        );
    };
    const fetchByChatState = async _filterChat => {
        
        dispatch({
            type: "USER_SEARCH_FILTER",
            isReaded:!_filterChat
        });
        getUserOrJobers(
            userSearch.levels,
            userSearch.location,
            userSearch.points,
            userSearch.leads,
            userSearch.startDate,
            userSearch.endDate,
            userSearch.guilds,
            null,
           _filterChat.value,
           userSearch.business
        );
    };

    const fetchByLeads = async leads => {
        dispatch({
            type: "USER_SEARCH_FILTER",
            leads: Number(leads)
        });
        getUserOrJobers(
            userSearch.levels,
            userSearch.location,
            userSearch.points,
            Number(leads),
            userSearch.startDate,
            userSearch.endDate,
            userSearch.guilds,
            null,
            null,
            userSearch.business
        );
    };

    const fetchByCity = async selection => {
        dispatch({
            type: "USER_SEARCH_FILTER",
            location: selection.location
        });
        getUserOrJobers(
            userSearch.levels,
            selection.location,
            userSearch.points,
            userSearch.leads,
            userSearch.startDate,
            userSearch.endDate,
            userSearch.guilds,
            null,
            null,
            userSearch.business
        );
    };

    const fetchByGuild = async selection => {
        dispatch({
            type: "USER_SEARCH_FILTER",
            guilds: selection
        });
        getUserOrJobers(
            userSearch.levels,
            userSearch.location,
            userSearch.points,
            userSearch.leads,
            userSearch.startDate,
            userSearch.endDate,
            selection,
            null,
            null,
            userSearch.business
        );
    };

    const fetchByBusiness = async businesses => {
        dispatch({
            type: "USER_SEARCH_FILTER",
            business: businesses
        });

        getUserOrJobers(
            userSearch.levels,
            userSearch.location,
            userSearch.points,
            userSearch.leads,
            userSearch.startDate,
            userSearch.endDate,
            userSearch.guilds,
            null,
            null,
            businesses
        );
    };

    const fetchByPhone = phone => {
        getUserOrJobers(null, null, null, null, null, null, null, phone, null);
    };

    const inputOnKeyPress = e => {
        const keyCode = e.keyCode || e.which;
        const query = e.target.value;
        if (keyCode === 13) {
            dispatch({
                type: "BUSINESS_SEARCH_FILTER",
                id: query
            });
            fetchByPhone(query);
        }
    };

    const optionOnChange =async  e => {
        const eValue = e.target.value;
       let selected = userSearch.typeArray.filter(item => {
           return item.id === eValue
        });
         dispatch({
            type: "USER_SEARCH_SET_IDTYPE",
            idType: selected[0].id
        });
        dispatch({
            type: "USER_SEARCH_SELECTED",
            selected: selected[0]
        });
        
        dispatch({
            type: "USER_SEARCH_FILTER",
            distance: null,
            location: null,
            points: 0,
            leads: 0,
            guilds: []
        });
        
        getUserOrJobers(
            userSearch.level,
            userSearch.provice,
            userSearch.points,
            userSearch.leads,
            null,
            null,
            null,
            null,
            null,
            null,
            selected[0].id
        );
    };

    const downloadUsersCSV = async () => {
        var data = new Array()
        var levels = {
            "1":"Bronce",
            "2":"Plata",
            "3":"Oro",
            "4":"Platino",
        }
        userSearch.items.map( async (item,index)=>{    
            
            await Promise.allSettled([
                item.get("Level"),
                item.get("Name"), 
                item.get("Phone"), 
                item.get("Reviews"), 
                item.get("Points"), 
                item.get("nPayments"), 
                item.get("Updated"), 
                item.get("createdAt")])  
                    .then(Responses=>{ 
                        var [Level, Name, Phone, Reviews, Points, nPayments, Updated, createdAt] = Responses
                    
                        data.push({
                            Level:Level.value? levels[Level.value]:levels[1],
                            Name:Name.value? Name.value:"N/A",
                            Phone:Phone.value? Phone.value:"N/A",
                            Reviews:Reviews.value? Reviews.value:"N/A",
                            Points:Points.value? Points.value:"N/A",
                            nPayments:nPayments.value? nPayments.value:"N/A",
                            Updated:Updated.value? Updated.value.toString():"N/A",
                            createdAt:createdAt.value? createdAt.value.toString():"N/A",
                        })
                        if(data.length == userSearch.items.length ){
                           var headers = {
                            Level:"Level",
                            Name:"Name",
                            Phone:"Phone",
                            Reviews:"Reviews",
                            Points:"Points",
                            nPayments:"nPayments",
                            Updated:"Updated",
                            createdAt:"createdAt",
                           }

                            csvRef.current.href = `data:text/csv,${encodeURI(arrayObjToCsv(data,headers))}`;
                            csvRef.current.download = `users-${new Date().toLocaleString(
                                "es-ES"
                            )}.csv`;
                            csvRef.current.click();
                        }
                    })
        })

    };

    const arrayObjToCsv = (arr,headers)=> {
     
        var contenido = ""
    
        Object.keys(headers).map((head)=>{
        contenido+= headers[head]+";"
        }); 
        contenido+="\n"

        arr.forEach((element,index) => {
            Object.keys(element).map((keys)=>{
                contenido += element[keys]+";";
            });
            contenido+="\n"
        }); 
         return contenido;
    };

    return (
        <section className="search-nav">
            {showImportModal && (
                <ImportWollersModalp
                    isVisible={showImportModal}
                    onClose={() => setShowImportModal(false)}
                />
            )}
            <div className="above">
                <div className="container-fluid">
                    <div className="col-md-6">
                        <div className="row">
                            <div
                                className="input-search-container"
                                style={{ width: "calc(100% - 120px)" }}
                            >
                                <select
                                    style={styles.select}
                                    onChange={optionOnChange}
                                    value={userSearch.selected.id}
                                >
                                    {userSearch.typeArray.map(item => {
                                        return (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.title == 'Jober' ? 'Woller' : item.title}
                                            </option>
                                        );
                                    })}
                                </select>
                               {!user?.isGoldWoller && ( <SearchBar
                                    style={{ marginLeft: 116 }}
                                    placeholder="Buscar por nº de teléfono"
                                    onKeyPress={inputOnKeyPress}
                                />)}
                            </div>
                        </div>
                    </div>
                    {!user?.isGoldWoller && (<div className="col-md-6 text-right">
                        <ButtonTextImage
                            buttonText="Importar"
                            style={{ display: "inline-block" }}
                            src={require("../../assets/import.svg")}
                            onClick={() => setShowImportModal(true)}
                        />
                        <ButtonTextImage
                            buttonText="Nuevo"
                            style={{ display: "inline-block" }}
                            src={require("../../assets/add.svg")}
                            onClick={() => {
                                props.history.push({
                                    pathname: "/user/signup/",
                                    state: {
                                        inner: true
                                    }
                                });
                            }}
                        />
                    </div>)}
                </div>
            </div>
            {!user?.isGoldWoller && (<div className="below">
                <div className="below-left">
                    <FilterDate
                        style={{ marginRight: 4 }}
                        type="date"
                        title="Última conexión"
                        startDate={null}
                        endDate={null}
                        selectionChange={fetchByDate}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="level"
                        title="Nivel"
                        items={levelArr}
                        selectionChange={fetchByLevel}
                    />
                    {userSearch.selected.id === "jober" && (
                        <React.Fragment>
                            <FilterSlider
                                style={{ marginRight: 4 }}
                                type="points"
                                title="Puntuación min."
                                minValue={0}
                                maxValue={5}
                                value={userSearch.points}
                                onBlur={fetchByPoints}
                            />
                            <FilterSlider
                                style={{ marginRight: 4 }}
                                type="points"
                                title="Créditos min."
                                minValue={0}
                                maxValue={30}
                                value={userSearch.leads}
                                onBlur={fetchByLeads}
                            />
                            <FilterSingleSelection
                                style={{ marginRight: 4 }}
                                type="city"
                                title="Ciudad"
                                items={provinceArr}
                                selectionChange={fetchByCity}
                            />
                            <FilterMultipleSelection
                                style={{ marginRight: 4 }}
                                type="tasks"
                                title="Gremios"
                                items={guildArr}
                                selectionChange={fetchByGuild}
                            />
                             <FilterSingleSelection
                                style={{ marginRight: 4 }}
                                type="tasks"
                                title="Chats"
                                items={[{id:"all",value:null,name:"Todos", selected: false},{id:"filter",value:true,name:"No Leidos", selected: false},{id:"noFilter",value:false,name:"Leidos",selected: false}]}
                                selectionChange={fetchByChatState}
                            />
                            <FilterMultipleSelection
                                style={{ marginRight: 4 }}
                                type="businesses"
                                title="Negocios"
                                items={businessArr}
                                selectionChange={fetchByBusiness}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>)}
        </section>
    );
}


