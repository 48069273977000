// import CryptoJS from "crypto-js"

function formatRelativeGeneralTime(currDate, jobDate) {
    const hoursDiff = currDate.getHours() - jobDate.getHours();
    if (hoursDiff > 0)
        return `hace ${hoursDiff} hora${hoursDiff === 1 ? "" : "s"}`;

    const minsDiff = currDate.getMinutes() - jobDate.getMinutes();
    if (minsDiff > 0)
        return `hace ${minsDiff} minuto${minsDiff === 1 ? "" : "s"}`;

    return "ahora";
}

function formatRelativeGeneralDate(isoDate, precise) {
    const jobDate = new Date(Date.parse(isoDate));
    const currDate = new Date();

    currDate.setHours(0, 0, 0, 0);
    jobDate.setHours(0, 0, 0, 0);

    const millisDif = currDate.getTime() - jobDate.getTime();
    const daysDif = Math.trunc(millisDif / 86400000); // 1 day = 24 * 3600 * 1000 = 8.640.000 millis

    if (daysDif === 0)
        return precise
            ? formatRelativeGeneralTime(
                  new Date(),
                  new Date(Date.parse(isoDate))
              )
            : "hoy";
    else if (daysDif === 1) return "ayer";
    else return `hace ${daysDif} días`;
}

export function formatRelativeDate(isoDate) {
    return `Subida ${formatRelativeGeneralDate(isoDate, false)}`;
}

export function formatRelativePreciseDate(isoDate) {
    return formatRelativeGeneralDate(isoDate, true);
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function elementOf(element, array) {
	return array.indexOf(element) !== -1;
}

export const getState = ( state ) => {
    switch (state) {
        case "estimated":
            return "Estimado";
        case "desestimated":
            return "Desestimado";
        case "pending":
            return "Pendiente";
        case undefined:
            return "Indefinido";
        default:
            return "";
    }
}

export const formatPostalCodeToProvince = ( postalCode ) => {
    if(postalCode === '01') return 'Alava'
    else if(postalCode === '02') return 'Albacete'
    else if(postalCode === '03') return 'Alicante'
    else if(postalCode === '04') return 'Almería'
    else if(postalCode === '05') return 'Ávila'
    else if(postalCode === '06') return 'Badajoz'
    else if(postalCode === '07') return 'Islas Baleares'
    else if(postalCode === '08') return 'Barcelona'
    else if(postalCode === '09') return 'Burgos'
    else if(postalCode === '10') return 'Cáceres'
    else if(postalCode === '11') return 'Cádiz'
    else if(postalCode === '12') return 'Castellón'
    else if(postalCode === '13') return 'Ciudad Real'
    else if(postalCode === '14') return 'Córdoba'
    else if(postalCode === '15') return 'La Coruña'
    else if(postalCode === '16') return 'Cuenca'
    else if(postalCode === '17') return 'Gerona'
    else if(postalCode === '18') return 'Granada'
    else if(postalCode === '19') return 'Guadalajara'
    else if(postalCode === '20') return 'Guipuzcoa'
    else if(postalCode === '21') return 'Huelva'
    else if(postalCode === '22') return 'Huesca'
    else if(postalCode === '23') return 'Jaen'
    else if(postalCode === '24') return 'León'
    else if(postalCode === '25') return 'Lérida'
    else if(postalCode === '26') return 'La Rioja'
    else if(postalCode === '27') return 'Lugo'
    else if(postalCode === '28') return 'Madrid'
    else if(postalCode === '29') return 'Málaga'
    else if(postalCode === '30') return 'Murcia'
    else if(postalCode === '31') return 'Navarra'
    else if(postalCode === '32') return 'Orense'
    else if(postalCode === '33') return 'Asturias'
    else if(postalCode === '34') return 'Palencia'
    else if(postalCode === '35') return 'Las Palmas'
    else if(postalCode === '36') return 'Pontevedra'
    else if(postalCode === '37') return 'Salamanca'
    else if(postalCode === '38') return 'Santa Cruz de Tenerife'
    else if(postalCode === '39') return 'Cantabria'
    else if(postalCode === '40') return 'Segovia'
    else if(postalCode === '41') return 'Sevilla'
    else if(postalCode === '42') return 'Soria'
    else if(postalCode === '43') return 'Tarragona'
    else if(postalCode === '44') return 'Teruel'
    else if(postalCode === '45') return 'Toledo'
    else if(postalCode === '46') return 'Valencia'
    else if(postalCode === '47') return 'Valladolid'
    else if(postalCode === '48') return 'Vizcaya'
    else if(postalCode === '49') return 'Zamora'
    else if(postalCode === '50') return 'Zaragoza'
    else if(postalCode === '51') return 'Ceuta'
    else if(postalCode === '52') return 'Melilla'
    else return 'No ES'
}

export const formatStringToNumberMonth = (stringMonth) => {
    if(stringMonth === 'Jan') return '01'
    else if(stringMonth === 'Feb') return '02'
    else if(stringMonth === 'Mar') return '03'
    else if(stringMonth === 'Apr') return '04'
    else if(stringMonth === 'May') return '05'
    else if(stringMonth === 'Jun') return '06'
    else if(stringMonth === 'Jul') return '07'
    else if(stringMonth === 'Aug') return '08'
    else if(stringMonth === 'Sep') return '09'
    else if(stringMonth === 'Oct') return '10'
    else if(stringMonth === 'Nov') return '11'
    else if(stringMonth === 'Dec') return '12'
    else return ''
}

export const formatCreatedAtCSV = (createdAt) => {
    return `${createdAt.toString().substring(8,10)}/${formatStringToNumberMonth(createdAt.toString().substring(4,7))}/${createdAt.toString().substring(11,15)}`
}

export const formatNotificationMessage = (createdAt) => {
    return `${createdAt.toString().substring(8,10)}/${formatStringToNumberMonth(createdAt.toString().substring(4,7))}/${createdAt.toString().substring(11,15)} ${createdAt.toString().substring(16,25)}`
}

export function formatStringForCSV( data, type ) {
    let csvString = '';
    if(type === 'lead'){
        //Add columns
        csvString = 'ID;Gremio;Titulo;Estado;Direccion;P.Libres;Nombre;Telefono;Desde;Precio por oferta(€);Negocio;Fecha de creacion;Total slots;Provincia;State;\r'
        //Add jobs
        data.map( job => {
            csvString = `${csvString} ${job?.id} ;${job?.get('Type')};${job?.get('Title')};${job?.get('State')};${job?.get('Address')};${job?.get('nJober')};${job?.get('CreatedBy')?.get('Name')};${job?.get('CreatedBy')?.get('Phone')};${job?.get('From')};${job?.get('Subvertical')?.get('nLeadsPerPC')?.default};${job?.get('Business')?.get('Name') ? job?.get('Business')?.get('Name') : ''};${formatCreatedAtCSV(job?.get('createdAt'))};${job?.get('TotalSlots')};${ formatPostalCodeToProvince(job?.get('PostalCode').substring(0, 2)) };${ getState(job.get('Budget')?.state) };\r`
            return null
        })
    }
    if(type === 'fixed'){
        //Add columns
        csvString = 'ID; Gremio; Titulo; Estado; Direccion; Nombre; Telefono; Desde; Precio; Precio con IVA; Precio por oferta(€); Negocio; Fecha de creacion \r'
        //Add jobs
        data.map( job => {
            csvString = `${csvString} ${job?.id} ; ${job?.get('Type')} ; ${job?.get('Title')}; ${job?.get('State')}; ${job?.get('Address')}; ${job?.get('CreatedBy')?.get('Name')}; ${job?.get('CreatedBy')?.get('Phone')}; ${job?.get('From')}; ${job?.get('PriceMax')?.toFixed(2)}; ${(job?.get('PriceMax') * 1.21).toFixed(2)}; ${job?.get('Vertical')?.get('nLeadsPerPC').default}; ${job?.get('Business')?.get('Name') ? job?.get('Business')?.get('Name') : ''}; ${job?.get('createdAt')}; \r`
            return null
        })
        
     }
     if(type === 'transport'){
        //Add columns
        csvString = 'ID del pedido; Direccion; Fecha de entrega; Nombre cliente; Tel. cliente; Desde; Precio por oferta; Negocio; Fecha de creacion; \r'
        //Add jobs
        // data.map( job => {
        //     csvString = `${csvString} ${job.jobID}; ${job.address}; ${job.deliveryDate ? (new Date(job.deliveryDate).toLocaleDateString("es-ES")) : null}; ${job.name}; ${job.phone}; ${job.from}; ${job.subVertical ? job.subVertical.get('nLeadsPerPC').default : null}; ${job.business ? job.business.get('Name') : ' '}; ${job.createdAt}; \r`
        // })
     }

    return csvString;
}

export function formatStringJobsWollerCSV (notificationInfo) {
    let csvString = 'ID;Title;Description;Fecha de creacion;Creado por;Estado;nJober;PostalCode;Direccion;TotalSlots;Nivel;JobType\r';

    notificationInfo.map( not => {
        const job = not?.get('Jobs');
        csvString = `${csvString} ${job.id}; ${job.get('Title')}; ${job.get('Description')};${formatCreatedAtCSV(job.get('createdAt'))};${job.get('State')};${job.get('nJober')};${job.get('PostalCode')};${job.get('Address')};${job.get('TotalSlots')};${job.get('Level')};${job.get('JobType')};\r`
        return null
    })

    return csvString;
}

export function formatStringPostalCodes ( postalCodes ) {
    let finalString = '';

    if(postalCodes){
        postalCodes.map( (postalCode,i) => {
            finalString = `${finalString}${postalCode} `
            return null
        });
    }

    return finalString;
}

export function formatState(job) {
    const status = job.get("State");
    const jobType = job.get("JobType");
    if (jobType === "fixed") {
        switch (status) {
            case "draft":
                return "Borrador";
            case "pending":
                return "Pendiente de cita";
            case "abierto":
                return "Pendiente de Woller";
            case "full":
                return "En proceso";
            case "signaturePending":
                return "Pendiente de firma";
            case "finalizado":
                return "Firmado";
            case "review":
                return "Servicio valorado";
            case "eliminado":
                return "Servicio eliminado";
            case "falso":
                return "Servicio falso";
            default:
                return "";

        }
    } else if (jobType === "transport") {
        switch (status) {
            case "draft":
                return "Borrador";
            case "pending":
                return "Pendiente de fecha de entrega";
            case "abierto":
                return "Pendiente de Woller";
            case "full":
                return "En reparto";
            case "signaturePending":
                return "Pendiente de firma";
            case "finalizado":
                return "Firmado";
            case "review":
                return "Servicio valorado";
            case "eliminado":
                return "Servicio eliminado";
            case "falso":
                return "Servicio falso";
            default:
                return "";
        }
    } else if (jobType === "lead") {
        switch (status) {
            case "abierto":
                return "Servicio abierto";
            case "full":
                return "Servicio lleno";
            case "finalizado":
                return "Servicio finalizado";
            case "review":
                return "Servicio valorado";
            case "eliminado":
                return "Servicio eliminado";
            case "falso":
                return "Servicio falso";
            case "noJobers":
                return "No hay Woller disponibles";
            case "standby":
                return "En espera";
            case "frozen":
                return "Servicio congelado";
            default:
                return "";
        }
    }
}

export function formatCurrency(value){
    var input_val = ""+value
    if (input_val === "") { return value; }
    
    // check for decimal
    if (input_val.indexOf(".") >= 0) {

        var decimal_pos = input_val.indexOf(".");
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        left_side = formatNumber(left_side);
        right_side = formatNumber(right_side);
        right_side += "00";
        right_side = right_side.substring(0, 2);
        input_val = left_side + "," + right_side +"€";

    } else {
        input_val = formatNumber(input_val);
        input_val = input_val +"€";
    }
    
    return  input_val
}

const formatNumber = (n)=>{
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const formatDate = ( date ) => {
    return `${date.substring(16,21)}  ${date.substring(8,10)}-${formatStringToNumberMonth(date.substring(4,7))}-${date.substring(11,15)}`
}

export const formatDateWhitoutHour = ( date ) => {
    return `${date.substring(8,10)}-${formatStringToNumberMonth(date.substring(4,7))}-${date.substring(11,15)}`
}