import React, {useState, useEffect} from "react";
import moment from "moment";
import LevelUpModal  from "../../modal/LevelUpModal";


import "../../../styles/Users/wollers/details.css";

import "moment/locale/es"  
export default function Details({user,userDetails, history}) {
    moment.locale('es')

    const [showLevelUpModal, setShowLevelUpModal] = useState(false)


   return (
    <div className="detailsContainer userDetails">
        {showLevelUpModal && <LevelUpModal
                isVisible={showLevelUpModal}
                onClose={()=>{setShowLevelUpModal(false)}}
            />}
        <div className="card profileInfo">
            <div className="personalInfo">
                <div>
                    <div className="profilePic" style={{backgroundImage:/*user.UserPic?.url()*/`url(${user.get("UserPic")?.url()})`}  } alt="userProfilePic">

                    <img className="levelIcon"
                        src={user?.get("Level") === 2 ? require("../../../assets/badgeSilver.png"):
                        user?.get("Level") === 3 ? require("../../../assets/badgeGold.png"):
                        user?.get("Level") === 4 ? require("../../../assets/badgePlatinum.png"):
                        require("../../../assets/badgeBronze.png")}
                        alt={user?.get("Level") === 2 ? "Plata":
                        user?.get("Level") === 3 ? "Oro":
                        user?.get("Level") === 4 ? "Platino":
                        "Bronce"}
                        
                        />
                    </div>
                </div>
                <p className="namePro">{user?.get("Name")?user?.get("Name"):"Profesional"}</p>
                <p>Profesional</p>
                
            </div>
            <div className="profesionalInfo">
                <div className="item">
                    <p className="itemNumber">{user?.get("Reviews")}</p>
                    <p className="itemText">evaluaciones</p>
                </div>
                <div className="item">
                    <p className="itemNumber">{user.get("Points").toFixed(2)}<img src={require("../../../assets/Pictogramas_Color_Estrellas.png")}/></p>
                    <p className="itemText">valoración</p>
                </div>
                <div className="item">
                    <p className="itemNumber">{(moment(user?.get("createdAt")).fromNow()).replace("hace","")}</p>
                    <p className="itemText">de experiencia como woller</p>
                </div>
            </div>

        </div>
        <div className={`card level ${user.get("Level") ==4 ? "platino": user.get("Level") ==3 ? "oro":user.get("Level") ==2 ? "plata":"bronze" }`}>
            {console.log("🚀 ~ file: Details.jsx:55 ~ Details ~ user.get():", typeof user.get("Level"), user.get("Level"))}
            
            <img src= {user.get("Level") ==4 ? require("../../../assets/badgePlatinum.png"): user.get("Level") ==3 ?require("../../../assets/badgeGold.png"):user.get("Level") ==2 ? require("../../../assets/badgeSilver.png"):require("../../../assets/badgeGold.png")} alt="" srcset="" />
            <div className="personalInfo">
                <p>{user.get("Level") ==1 ? "CONVIERTETE EN ":"TU NIVEL"}</p>
                <p className="levelText">{user.get("Level") ==4 ? "PLATINO": user.get("Level") ==3 ? "ORO":user.get("Level") ==2 ? "PLATA":"ORO"}</p>
                {user.get("Level") <= 2 ? (<div onClick={()=>{setShowLevelUpModal(true)}} className="levelUpButton">{user.get("Level") ==2 ? "SUBE A ORO":"HOMOLOGATE"}</div>):null}
            </div>
           

        </div>
        <div className="accountState">
            <h4>Información confirmada de {user?.get("Name")?user?.get("Name"):"Profesional"}</h4>
            <div className="accountItem">
                <img src={userDetails?.get("DNI") != undefined  ? 
                            require("../../../assets/check-solid.svg"):
                            require("../../../assets/xmark-solid.svg")}/> 
                Identidad
            </div>
            <div className="accountItem">
                <img src={user?.get("emailVerified") == true  && user?.get("email") != undefined  ? 
                            require("../../../assets/check-solid.svg"):
                            require("../../../assets/xmark-solid.svg")}/> 
                Dirección de correo electrónico 
            </div>
            <div className="accountItem">
                <img src={user?.get("Phone") != undefined  ? 
                            require("../../../assets/check-solid.svg"):
                            require("../../../assets/xmark-solid.svg")}/> 
                Número de teléfono
            </div>
        </div>
        
    </div>
   )
}