export const joberTitles = [
    "",
    "Nombre",
    "Teléfono",
    "Nº Reviews",
    "Puntuación",
    "Créditos",
    "Nº de compras",
    "Última conexión",
    "Fecha registro",
    "Mensajes",
    "Business"
];
export const goldJoberTitles = [
    "",
    "Nombre",
    "Teléfono",
    "Nº Reviews",
    "Puntuación",
    "Créditos",
    "Nº de compras",
    "Última conexión",
    "Fecha registro",
    "Business"
];

export const clientTitles = [
    "",
    "Nombre",
    "Teléfono",
    "Última conexión",
    "Fecha registro"
];

