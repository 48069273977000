import React, { useEffect } from "react";
import { useStateValue } from "../../state/context";

import SearchNav from "../../components/search/SearchNav";
import ResultTable from "../../components/search/ResultTable";
import ResultCount from "../../components/search/ResultCount";
import Pipedrive from "../../components/search/Pipedrive";
import {findJobsByFilters as findJobsByFilters_  } from "../../utils/ParserUtilities";


export default function Search(props) {

    const [{search, user},dispatch] = useStateValue();
    const findJobsByFilters = ({
        startDate=undefined,
        endDate=undefined,
        appointmentStart=undefined,
        appointmentEnd=undefined,
        provinces=undefined,
        guilds=undefined,
        levels=undefined,
        slots=undefined,
        jobStates=undefined,
        agents=undefined,
        businesses=undefined,
        budget=undefined,
        onlyIncidences=undefined,
        page=undefined,
        type=undefined,
    })=>{
    dispatch({
        type: "SEARCH_LOADING",
        loading: true,
    });
  
    let  jobsPromise = findJobsByFilters_({
        type:type? type:search.currentIdType,
        startDate:startDate ? new Date(startDate): startDate === null? startDate:search.startDate,
        endDate:endDate ? new Date(endDate): endDate === null? endDate:search.endDate,
        provinces:provinces && provinces !== undefined? provinces:search.provinces,
        guilds:guilds && guilds !== undefined? guilds:search.guilds,
        levels:levels && levels !== undefined? levels:search.levels,
        slots:slots && slots !== undefined? slots:search.slots,
        jobStates:jobStates && jobStates !== undefined? jobStates:search.jobStates,
        agents:agents && agents !== undefined? agents:search.agents,
        businesses:businesses && businesses !== undefined? businesses:search.businesses,
        budget:budget && budget !== undefined? budget:search.budget,
        onlyIncidences:onlyIncidences !== undefined ? onlyIncidences:search.onlyIncidences,
        appointmentStart:appointmentStart? new Date(appointmentStart): appointmentStart === null ? appointmentStart :search.appointmentStart ,
        appointmentEnd:appointmentEnd? new Date(appointmentEnd): appointmentEnd === null ? appointmentEnd :search.appointmentEnd ,
        limit:search.limit,
        page:page?page:search.currentPage,
        userType:user.isB2b ? "b2b":user.isGoldWoller ? "goldUser":user.isMediator ? "mediator":"agent"
    })

    Promise.all([jobsPromise]).then(([results]) => {
        dispatch({
            type: "SEARCH_RESULTS",
            services:results.services,
            count:results.count
        });
        dispatch({
            type: "SEARCH_LOADING",
            loading: false,
        });
        
        
    })
    .catch((error) => {
        dispatch({
            type: "SEARCH_LOADING",
            loading: false,
        });
        
    })
}


    return (
        <div className="search-wrapper">
            <SearchNav history={props.history} location={props.location} findJobsByFilters={findJobsByFilters}/>
            {search.pipedrive && !user?.isMediator ? 
                <Pipedrive history={props.history} location={props.location}/>
            :
                <ResultTable history={props.history} location={props.location} />
            }
            <ResultCount history={props.history} location={props.location} controllerState={search} findFunction={findJobsByFilters} reducerFunction={"SEARCH_CHANGE_PAGE"}/>
        </div>
    );
}
